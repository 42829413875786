import useAxiosRequest from '@/workers/AxiosRequest.js';
import useErrorCreate from '@/workers/ErrorCreate.js';


export default {
  state: {

    //Данные внешних ресурсов
    outsideData: [],

    //Статус подгрузки внешних ресурсов
    outsideFilled: false,

  },

  getters: {

    //Получение внешних данных
    outsideDataGet(state) {
      return state.outsideData
    },

    //Получение статуса подгрузки внешних ресурсов
    outsideFilledGet(state) {
      return state.outsideFilled
    }

  },

  mutations: {

    //Изменение внешних ресурсов
    setOutsideData(state, data) {
      state.outsideData = data;
    },

    //Изменение статуса подгрузки внешних ресурсов
    setOutsideFilled(state, bool) {
      state.outsideFilled = bool;
    },

  },

  actions: {

    async reqOutsideData(context){

      //Получаем линки linkBase
      const linkBase = context.getters.linkBaseGet;

      //Получаем токкен
      const token = context.rootState.userValues.nowToken;

      //Находим линк по action
      const linkOutside = context.getters.linkOutsideGet;

      //Формируем параметры запроса
      const params = {'page': Number(context.rootState.activeValues.activePageNumber),
                      'page_size': Number(context.rootState.activeValues.activePostsPage),
                      'section': context.rootState.basicValues.nowSlug,
                      'language': context.rootState.activeValues.activeLanguage};

      //Подгружаем ресурсы
      const outsideData = await useAxiosRequest(linkBase + linkOutside, 'GET', '', params, token);

      if (outsideData.status) {
        //Вычисляем количество страниц и коммитим
        context.commit('setActivePageCount',
                       Math.ceil(outsideData.data.count/Number(context.getters.activePostsPageGet)));

        //Коммитим полученные данные
        context.commit('setOutsideData', outsideData.data.results);

        //Коммитим заверешие подгрузки
        context.commit('setOutsideFilled', true);
      }
      else {
        //Коммитим пустые данные
        context.commit('setOutsideData', []);

        //Получаем и комитим ошибку
        const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                      context.getters.errorsAllGet['errorRequestOutside']);
        context.commit('setNowErrorText', errors);
      }
    }

  }
}