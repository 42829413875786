<template>
  <input
    class="form-control myClass"
    :value="modelValue"
    :placeholder="placeholder"
    @input="updateInput"
  >
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    placeholder:String
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style scoped>
.myClass {
  color: #0a689e;
}
</style>
