<template>
  <div class="modal"
   id="modalDeleteData"
   tabindex="-1"
   aria-labelledby="exampleDeleteData"
   aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header bg-secondary text-white">
          <div class="modal-title"
           id="exampleDeleteData"
          >{{ textLabel.nameFormDeleteData[language] }}</div>
            <button
             type="button"
             class="btn-close"
             @click="$emit('cancelModal')"
             aria-label="Close"
            >
            </button>
          </div>
        <div class="modal-body">
          <div>
            <label class="myRed"><b>{{ textLabel.txtFormDeleteData[language] }}</b></label>
          </div>
        </div>
        <div class="modal-footer bg-secondary text-white">
          <custom-button
           @click="$emit('confirmModal')"
          >
            {{ textLabel.btnDeleteName[language] }}
          </custom-button>
          <custom-button
           @click="$emit('cancelModal')"
          >
            {{ textLabel.btnCancelName[language] }}
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';

export default {
  components: {
    CustomButton,
  },
  props: {
    textLabel: {
      type: Object,
    },
    language: {
      type: String,
    },
  },
  setup() {
    return {
    }
  }
}
</script>

<style scoped>
input {
    margin: 0.3rem;
}

.btn-primary {
  color: #fff;
  background-color: #0a689e;
  border-color: #0a689e;
}

.modal-header {
  padding: 0.4rem;
}

.modal-footer {
  padding: 0.1rem;
}
.myRed {
 color: red;
}
</style>