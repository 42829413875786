import useAxiosRequest from '@/workers/AxiosRequest.js';
import useArrayToTree from '@/workers/ArrayToTree.js';
import useErrorCreate from '@/workers/ErrorCreate.js';
import useTakeObject from '@/workers/TakeObject.js';
import useTakeValue from '@/workers/TakeValue.js';


export default {
  state: {

    //Стартовые данные и их подгрузка
    startData: [],
    startFilled: false,

    //Дерево меню при аутентификации
    treeLogin: [],

    //Дерево меню без аутентификации
    treeLogout: [],

    //Текущий слаг
    nowSlug: '',

  },

  getters: {

    //Получение стартовых данных
    startDataGet(state) {
      return state.startData;
    },

    //Получение подтверждения загрузки стартовых данных
    startFilledGet(state) {
      return state.startFilled;
    },

    //Получение меню при аутентификации
    treeLoginGet(state) {
      return state.treeLogin;
    },

    //Получение меню при отсутствии аутентификации
    treeLogoutGet(state) {
      return state.treeLogout;
    },

    //Получение подписей к данным
    textLabelGet(state, getters) {
      if (state.startData['texts']?.['Text']?.['info']) {
        return state.startData['texts']?.['Text']?.['info'];
      }
      else {
        return getters.textLabelDefaultGet;
      }
    },

    //Получение информации о разделах сайта
    infoSiteGet(state) {
      return useTakeObject(state.startData['presents']?.['PresentInfo']?.['info']);
    },

    //Получение информации о разработчике
    detailDeveloperGet(state) {
      return useTakeObject(state.startData['contacts']?.['detailDeveloper']?.['info']);
    },

    //Получение информации контактов разработчика проекта
    contactDeveloperGet(state) {
      return useTakeObject(state.startData['contacts']?.['contactDeveloper']?.['info']);
    },

    //Получение информации контактов партнеров проекта
    contactPartnerGet(state) {
      return useTakeObject(state.startData['contacts']?.['contactPartner']?.['info']);
    },

    //Получение информации о сервисах сайта
    serviceSiteGet(state) {
      return useTakeObject(state.startData['services']?.['serviceAll']?.['info']);
    },

    //Получение текущего слага
    nowSlugGet(state) {
      return state.nowSlug
    },

    //Получение текста раздела
    nowSectionTextGet(state, getters) {
      if (getters.nowSlugGet) {
        return useTakeValue(state.startData['menus']?.['menuAll']?.['info'],
                            'slug', getters.nowSlugGet, 'text', 0);
      }
      else {
        return getters.textLabelGet['txtMainPage']
      }

    },

    //Получение экшена раздела
    nowActionGet(state, getters) {
      return useTakeValue(state.startData['menus']?.['menuAll']?.['info'], 'slug', getters.nowSlugGet, 'action', 0);
    },

    //Получение ошибок
    errorsAllGet(state) {
      return useTakeObject(state.startData['errors']?.['txtError']?.['info']);
    },

    excelToolsGet(state) {
      return useTakeObject(state.startData['foreigners']?.['excelData']?.['info']?.['params']);
    },

    modelsToolsGet(state) {
      return useTakeObject(state.startData['tools']?.['toolPage']?.['info']);
    },

    modelsBasicGet(state) {
      return useTakeObject(state.startData['models']);
    },

    interfacesBasicGet(state) {
       return useTakeObject(state.startData['interfaces']);
    },

    linkRegisterGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'register_user', 'link', 0);
    },

    linkLoginGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'login_user', 'link', 0);
    },

    linkUserGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'custom_user', 'link', 0);
    },

    linkLogoutGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'logout_user', 'link', 0);
    },

    linkOutsideGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'source_outside', 'link', 0);
    },

    linkIndustrialGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'industrial_data', 'link', 0);
    },

    linkFactGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'fact_data', 'link', 0);
    },

    linkAuxiliaryGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'auxiliary_data', 'link', 0);
    },

    linkTaxGet(state) {
      return useTakeValue(state.startData['links']?.['linkAll']?.['info'], 'action', 'tax_data', 'link', 0);
    },

    selectsDefaultGet(state) {
      return useTakeObject(state.startData['tools']?.['toolDefault']?.['info']);
    },

    selectsLanguageGet(state) {
      return useTakeObject(state.startData['selects']?.['Language']?.['info']);
    },

    selectGroupGet(state) {
      return useTakeObject(state.startData['selects']?.['Group']?.['info']);
    },

    selectSpeechGet(state) {
      return useTakeObject(state.startData['selects']?.['Speech']?.['info']);
    },

    selectAuxGet(state) {
      return useTakeObject(state.startData['selects']?.['Aux']?.['info']);
    },

    selectYearGet(state) {
      return useTakeObject(state.startData['selects']?.['Year']?.['info']);
    },

    selectQuarterGet(state) {
      return useTakeObject(state.startData['selects']?.['Quarter']?.['info']);
    },

    selectSpecificGet(state) {
      return useTakeObject(state.startData['selects']?.['Specific']?.['info']);
    },

    selectVariantGet(state) {
      return useTakeObject(state.startData['selects']?.['Variant']?.['info']);
    },

    selectStrainerGet(state) {
      return useTakeObject(state.startData['selects']?.['Strainer']?.['info']);
    },

    selectSignGet(state) {
      return useTakeObject(state.startData['selects']?.['Sign']?.['info']);
    },

    selectDirectGet(state) {
      return useTakeObject(state.startData['selects']?.['Direct']?.['info']);
    },

    selectInterfaceGet(state) {
      return useTakeObject(state.startData['selects']?.['Interface']?.['info']);
    },

    selectBringGet(state) {
      return useTakeObject(state.startData['selects']?.['Bring']?.['info']);
    },

    selectActionGet(state) {
      return useTakeObject(state.startData['selects']?.['Action']?.['info']);
    },

    selectTaxGet(state) {
      return useTakeObject(state.startData['selects']?.['Tax']?.['info']);
    },

    selectOutsideGet(state) {
      return useTakeObject(state.startData['selects']?.['Outside']?.['info']);
    },

    selectCountPostGet(state) {
      return useTakeObject(state.startData['selects']?.['CountPost']?.['info']);
    },

    selectTRF870Get(state) {
      return useTakeObject(state.startData['selects']?.['TRF870']?.['info']);
    },

    selectDefectiveGet(state) {
      return useTakeObject(state.startData['selects']?.['Defective']?.['info']);
    },

    selectSavingGet(state) {
      return useTakeObject(state.startData['selects']?.['Saving']?.['info']);
    },

  },

  mutations: {

    //Изменяем стартовые данные
    setStartData(state, data) {
      state.startData = data;
    },

    //Изменяем статус подгрузки стартовых данных
    setStartFilled(state, bool) {
      state.startFilled = bool;
    },

    //Изменяем главное меню с учетом аутентификации
    setTreeLogin(state, tree) {
      state.treeLogin = tree;
    },

    //Изменяем главное меню с учетом отсутствия аутентификации
    setTreeLogout(state, tree) {
      state.treeLogout = tree;
    },

    //Изменяем текущий слаг
    setNowSlug(state, slug) {
      state.nowSlug = slug;
    },

  },

  actions: {
    activeVariablesUpdate(context) {
      const selectsDefault = JSON.parse(JSON.stringify(context.getters.selectsDefaultGet));
      for (let key of Object.keys(selectsDefault)) {
        context.commit(selectsDefault[key].commit, selectsDefault[key].value);
      }
    },

    groupsUserUpdate(context) {
      const groupsUser = JSON.parse(JSON.stringify(context.getters.selectGroupGet));
      context.commit('setGroupsUser', groupsUser);
    },

    interfacesUserUpdate(context) {
      const interfacesUser = JSON.parse(JSON.stringify(context.getters.interfacesBasicGet));
      context.commit('setInterfacesUser', interfacesUser);
    },

    async reqStartData(context) {
      if (!context.getters.startFilledGet) {

        //Получаем линки linkBase и linkStart
        const linkBase = context.getters.linkBaseGet;
        const linkStart = context.getters.linkStartGet;

        //Формируем параметры запроса "start"
        const params = {'load': 'start'};

        //Запрашиваем стартовые данные из БД
        const startData = await useAxiosRequest(linkBase + linkStart, 'GET', '', params, '');

        //При удачном получении данных
        if (startData.status && startData.data.posts.length ) {
          //Создаем объект стартовых данных
          const objStart = {};

          //Проходим по всему списку полученных с сервера данных
          for (let record of startData.data.posts) {

            //Текстовое поле 'info' преобразуем в объект
            const infoField = (record.info.length > 0) ? JSON.parse(record.info) : []

            //Помещаем данные в объект предварительно проверив его существование
            if (objStart[record.detail] == undefined) {
              objStart[record.detail] = {};
            }
            objStart[record.detail][record.code] = { 'info': infoField };
          }

          //Коммитим стартовые данные
          context.commit('setStartData', objStart);
          context.commit('setStartFilled', true);

          //Коммитим обновление активных значений селектов
          context.dispatch('activeVariablesUpdate');

          //Коммитим обновление групп пользователя (select)
          context.dispatch('groupsUserUpdate');

          //Коммитим обновление интерфейсов пользователя
          context.dispatch('interfacesUserUpdate');

          //Создаем меню из стартовых данных
          const mainMenu = objStart['menus']?.['menuAll']?.['info']
          if (mainMenu) {
            const treeLogin = useArrayToTree(JSON.parse(JSON.stringify(mainMenu.filter(item => item['client'] === 1))));
            const treeLogout = useArrayToTree(JSON.parse(JSON.stringify(mainMenu.filter(item => item['guest'] === 1))));
            context.commit('setTreeLogin', treeLogin);
            context.commit('setTreeLogout', treeLogout);
          }
          else {
            const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                          context.getters.errorsAllGet['errorCreateMenu']);
            context.commit('setNowErrorText', errors);
          }

        }
        else {
          //Коммитим ошибку
          const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                        context.getters.errorStartGet);
          context.commit('setNowErrorText', errors);
        }
      }
    },
  }
}