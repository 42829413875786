<template>
  <div v-if="!countFilled">
    <div class="container mt-1">
      {{ textLabel.txtNoData[language] }}
    </div>
  </div>
  <div v-else>
    <div class="container mt-3">
      <div class="row">
        <div class="col-6">
          <custom-input
            type="text"
            :placeholder="textLabel.txtPlaceholderFind[language]"
            v-model="searchQuery"
          >
          </custom-input>
        </div>
        <div class="col">
          <custom-select
            v-model="outsideActive"
            :disabledValue="outside.forbidden[language]"
            :selectOptions="outside.params"
            :language="language"
            @customOption="outsideChange"
          >
          </custom-select>
        </div>
        <div class="col">
          <custom-select
            v-model="directActive"
            :disabledValue="direct.forbidden[language]"
            :selectOptions="direct.params"
            :language="language"
            @customOption="directChange"
          >
          </custom-select>
        </div>
      </div>
    </div>
    <list-cards
      :posts="searchedPosts"
      :textLabel="textLabel"
      :language="language"
    >
    </list-cards>
    <div v-if="activePageCount > 1">
      <custom-paginate
        :pageCount="activePageCount"
        @changePagination="paginationChange"
      >
      </custom-paginate>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import CustomInput from "@/components/CustomInput.vue";
import CustomPaginate from "@/components/CustomPaginate.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import ListCards from "@/cards/ListCards.vue";
import useArraySearched from "@/workers/ArraySearched.js";
import useArraySorted from "@/workers/ArraySorted.js";


export default {
  components: {
    CustomInput,
    CustomPaginate,
    CustomSelect,
    ListCards,
  },
  setup () {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Поисковая строка - фильтрация
    const searchQuery = ref('');

    //Селект - сортировка ресурсов
    const outside = store.getters.selectOutsideGet;
    const outsideActive = computed(() => store.getters.activeOutsideGet);

    //Селект - направление сортировки
    const direct = store.getters.selectDirectGet;
    const directActive = computed(() => store.getters.activeDirectGet);

    //Подгрузка ресурсов
    const outsideFilled = computed(() => store.getters.outsideFilledGet);

    //Определяем текущие данные ресурсов
    const outsideData = computed(() => store.getters.outsideDataGet);

    //Определяем число страниц - пагинация
    const activePageCount = computed(() => Number(store.getters.activePageCountGet));

    //При false отображаем "Данные в разделе отсутствуют"
    const countFilled = computed(() => store.getters.outsideDataGet.length ? true : false);

    const sortedPosts = computed(() => useArraySorted(outsideData.value, outsideActive.value, directActive.value));
    const searchedPosts = computed(() => useArraySearched(sortedPosts.value, outsideActive.value, searchQuery.value));


    function directChange(data) {
      store.commit('setActiveDirect', data);
    }

    function outsideChange(data) {
      store.commit('setActiveOutside', data);
    }

    function paginationChange(data) {

      //Коммитим номер страницы
      store.commit('setActivePageNumber', data);

      //Вызываем перезагрузку ресурсов
      store.dispatch('reqOutsideData');
    }

    return {
      language,
      textLabel,

      searchQuery,

      outside,
      outsideActive,
      outsideChange,

      direct,
      directActive,
      directChange,

      searchedPosts,
      activePageCount,
      countFilled,
      outsideFilled,

      paginationChange
    }
  }
}
</script>

<style scoped>
.myColor {
  color: #0a689e;
}
</style>
