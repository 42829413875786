<template>

  <!-- Отображение выбора характеристик объекта  -->
  <div class="container mt-3">
    <div class="row">
      <div class="col-8">
        <div v-if="aux != 'bad'">
          <custom-select
           v-model="auxActive"
           :disabledValue="aux.forbidden[language]"
           :selectOptions="aux.params"
           :language="language"
           @customOption="auxChange"
          >
          </custom-select>
        </div>
      </div>

      <div class="col-2">
        <div v-if="year != 'bad'">
          <custom-select
           v-model="yearActive"
           :disabledValue="year.forbidden[language]"
           :selectOptions="year.params"
           :language="language"
           @customOption="yearChange"
          >
          </custom-select>
        </div>

      </div>
      <div class="col-2">
        <div v-if="bring != 'bad'">
          <custom-select
           v-model="bringActive"
           :disabledValue="bring.forbidden[language]"
           :selectOptions="bring.params"
           :language="language"
           @customOption="bringChange"
          >
          </custom-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomSelect from "@/components/CustomSelect.vue";


export default {
  components: {
    CustomSelect,
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Селект - Характеристики
    const aux = computed(() => store.getters.selectAuxGet);
    const auxActive = computed(() => store.getters.textModelDataGet);

    //Определяем селект год
    const year = computed(() => store.getters.selectYearGet);
    const yearActive = computed(() => store.getters.activeYearGet);

    //Определение селекта ввод/экспорт данных
    const bring = computed(() => store.getters.selectBringGet);
    const bringActive = computed(() => store.getters.activeBringGet);


    function auxChange(data) {
      auxActive.value = data;
    }

    function yearChange(data) {
      yearActive.value = data
    }

    function bringChange(data) {
      bringActive.value = data
    }


    return {
      language,
      textLabel,

      aux,
      auxActive,
      auxChange,

      year,
      yearActive,
      yearChange,

      bring,
      bringActive,
      bringChange
    }
  }
}
</script>
<style scoped>
</style>