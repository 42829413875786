<template>
  <div class="container mt-3">
    <div class="card">
      <h5 class="card-header">{{ post.name }}</h5>
      <div class="card-header">
      {{ txtAcceptance }} {{ post.adoption_number }} {{ txtFrom }} {{ post.adoption_date }} ({{ post.adoption_gov }})
      </div>
      <div class="card-body">
        <!-- <img src="post.picture" class="card-img-top" alt="picture"> -->
        <p class="card-text">{{ post.content }}</p>
        <p class="card-text">{{ txtForce }} <strong>{{post.force}}</strong>. {{ txtStatus }} <strong>{{post.status}}</strong>.</p>
       <a :href="post.link" class="btn btn-primary" target="_blank">{{ btnText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    txtAcceptance: {
      type: String,
    },
    txtFrom: {
      type: String,
    },
    txtForce: {
      type: String,
    },
    txtStatus: {
      type: String,
    },
    btnText: {
      type: String,
    }
  }
}
</script>

<style scoped>
.btn-primary {
    background-color: #0a689e;
    border-color: #0a689e;
}
</style>
