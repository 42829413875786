//Возвращает отсортированный массив отображаемых полей

import useObjectToArray from "@/workers/ObjectToArray.js";
import useArrayFiltered from "@/workers/ArrayFiltered.js";
import useArraySorted from "@/workers/ArraySorted.js";


export default function useFieldsVisible(params, face, model, worker) {
  //Создаем масив для видимых полей
  const fieldsVisible = [];

  //Объект params преобразуем в массив
  const fields = useObjectToArray(params);

  //Проходимся по массиву (полям) при содержании
  for (let field of fields) {
    //Визуализируем поле "name"
    if (field['name'] === 'name') {
      field['visible'] = true
    }
    if (field['visible']) {
      const fieldModel = useArrayFiltered(model.info.params, 'name', field['name'])
      //При содержании в модели 'visible' в true пушим поле в массив 'fieldsVisible'
      if (fieldModel.length) {
         if (fieldModel[0]['table'][worker][face]) {
           field['value'] = field['title']
           fieldsVisible.push(field);
         }
      }
    }
  }
  //Сортируем массив по свойству 'sort'
  return useArraySorted(fieldsVisible, 'sort', 'direct')
}
