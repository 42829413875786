<template>
  <div class="container mt-4">
    Раздел "Картографические данные" находится в стадии тестирования
  </div>
</template>
<script>

</script>
<style scoped>
</style>
