<template>
<div class="container mt-3">
  <div class="row">
    <div class="col-lg-12">
      <div class="contacts">
        <h5>{{ textLabel.projectManager[language] }}</h5>
        <p><b>{{ contactDeveloper.name[language] }}</b></p>
        <p><b><i>{{ textLabel.phoneShort[language] }}</i></b> {{ contactDeveloper.phone[language] }}</p>
        <p><b><i>{{ textLabel.emailBasic[language] }}</i></b> {{ contactDeveloper.email[language] }} </p>
        <br>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="contacts">
        <h5>{{ textLabel.projectPartners[language] }}</h5>
      </div>
    </div>
  </div>
  <div class="row" v-for="contact in contactPartner" :key="contact.id">
    <div class="col-lg-12">
      <div class="contacts">
        <p><strong>{{ contact.name[language] }}</strong></p>
        <p><b><i>{{ textLabel.addressBasic[language] }}</i></b> {{ contact.address[language] }}</p>
        <p><b><i>{{ textLabel.phoneShort[language] }}</i></b> {{ contact.phone[language] }}</p>
        <p><b><i>{{ textLabel.emailBasic[language] }}</i></b> {{ contact.email[language] }}</p>
        <p><b><i>{{ textLabel.fieldActivity[language] }}</i></b> {{ contact.activity[language] }}</p>
        <br>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import useTakeObject from "@/workers/TakeObject.js";


export default {
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Контакты разработчика
    const contactDeveloper = computed(() => store.getters.contactDeveloperGet);

    //Контакты партнеров
    const contactPartner = computed(() => store.getters.contactPartnerGet);

    return {
      language,
      textLabel,
      contactDeveloper,
      contactPartner
    }
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0.1rem
}
.contacts {
  color: #0a689e;
}
</style>
