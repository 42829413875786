<template>
  <div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';


export default {
  setup() {
    const router = useRouter();

    //Переходим на главную страницу
    router.push("/");

    return {
    }
  }
}
</script>

<style scoped>
</style>