import useAxiosRequest from '@/workers/AxiosRequest.js';
import useLocalClear from '@/workers/LocalClear.js';
import useLocalRead from '@/workers/LocalRead.js';
import useLocalWrite from '@/workers/LocalWrite.js';


export default {
  state: {

    //Авторизован пользователь или нет
    userAuthorized: false,

    //Определяем возможность или невозможность регистрации
    registerFilled: true,

    //Определяем возможность или невозможность входа
    loginFilled: true,

    //Определяем возможность или невозможность выхода
    logoutFilled: true,

    //Определяем возможность или невозможность обновления данных пользователя
    updateFilled: true,

    //Текущий user
    nowUser: {},

    //Текущий токен
    nowToken: '',

    //Информация о пользователе
    infoUser: {},

  },

  getters: {

    //Получаем статус авторизации пользователя
    userAuthorizedGet(state) {
      return state.userAuthorized
    },

    //Получаем статус регистрации пользователя
    registerFilledGet(state) {
      return state.registerFilled
    },

    //Получаем статус входа пользователя
    loginFilledGet(state) {
      return state.loginFilled
    },

    //Получаем статус выхода пользователя
    logoutFilledGet(state) {
      return state.logoutFilled
    },

    //Получаем статус обновления пользователя
    updateFilledGet(state) {
      return state.updateFilled
    },

    //Получаем пользователя
    nowUserGet(state) {
      return state.nowUser
    },

    //Получаем токен пользователя
    nowTokenGet(state) {
      return state.nowToken
    },

    //Получаем информацию о пользователе
    infoUserGet(state) {
      return state.infoUser
    },

    //Получаем статус пользователя
    statusWorkerGet(state) {
      return state.infoUser.mode ? 'curator' : 'client'
    },

  },

  mutations: {

    //Изменяем статус авторизации
    setUserAuthorized(state, bool) {
      state.userAuthorized = bool;
    },

    //Изменяем статус регистрации
    setRegisterFilled(state, bool) {
      state.registerFilled = bool;
    },

    //Изменяем статус входа
    setLoginFilled(state, bool) {
      state.loginFilled = bool;
    },

    //Изменяем статус выхода
    setLogoutFilled(state, bool) {
      state.logoutFilled = bool;
    },

    //Изменяем статус обновления данных пользователя
    setUpdateFilled(state, bool) {
      state.updateFilled = bool;
    },

    //Изменяем пользователя
    setNowUser(state, user) {
      state.nowUser = user;
    },

    //Изменяем токен пользователя
    setNowToken(state, token) {
      state.nowToken = token;
    },

    //Изменяем информацию о пользователе
    setInfoUser(state, info) {
      state.infoUser = info;
    },

  },

  actions: {

    async reqUserRegister(context) {
      //Получаем линки linkBase
      const linkBase = context.getters.linkBaseGet;

      //Получаем линк регистрации
      const linkRegister = context.getters.linkRegisterGet;

      //Регистрируем пользователя
      const regUser = await useAxiosRequest(linkBase + linkRegister, 'POST', context.getters.nowUserGet, '', '');

      if (regUser.status) {
        //Блокируем возможность регистрации пользователя
        //context.commit('setRegisterFilled', true);

        //Аутентифицируемся
        await context.dispatch('reqUserLogin');
      }
      else {
        //Коммитим ошибку и ее текст
        console.log('Ошибка')
      }
    },

    async reqUserLogin(context) {

      //При отсутствии авторизации
      if (!context.getters.userAuthorizedGet) {

        if (context.getters.loginFilledGet) {
          const localUser = useLocalRead(['username', 'password']);
          if (localUser.username && localUser.password) {
            //Сохраняем пользователя из localStorage
            context.commit('setNowUser', localUser)
          }
        }

        //Если username и password инициализированы
        if (context.getters.nowUserGet.username && context.getters.nowUserGet.password) {

          //Получаем линки linkBase
          const linkBase = context.getters.linkBaseGet;

          //Получаем линк входа
          const linkLogin = context.getters.linkLoginGet;

          //Поучаем токен
          const token = await useAxiosRequest(linkBase + linkLogin, 'POST', context.getters.nowUserGet, '', '');

          if (token.status) {

            //Получаем данные пользователя
            await context.dispatch('reqUserInfo', token.data.auth_token)

            //Сохраняем данные в LocalStorage
            useLocalWrite(['username', 'password'], context.state.nowUser);

            //Коммитим токкен
            context.commit('setNowToken', token.data.auth_token)

            //Подтверждаем авторизацию
            context.commit('setUserAuthorized', true);

          }
          else {
            //Удаляем ключи из LocalStorage
            useLocalClear(['username', 'password']);

            //Коммитим ошибку и ее текст
            console.log('Ошибка')
          }
        }
        else {
          useLocalClear(['username', 'password']);
        }
      }
      //Коммитим завершение попытки авторизации
      //context.commit('setLoginFilled', true);
    },

    async reqUserInfo(context, tokenUser) {
      //Получаем линки linkBase
      const linkBase = context.getters.linkBaseGet;

      //Получаем линк данных пользователя
      const linkUser = context.getters.linkUserGet;

      //Поучаем данные пользователя
      const userInfo = await useAxiosRequest(linkBase + linkUser, 'GET', '', '', tokenUser);

      //При удачном получение данных
      if (userInfo.status) {

        //Текстовое поле 'info' преобразуем в объект
        userInfo.data.posts[0]['info'] = (userInfo.data.posts[0]['info'].length > 0) ? JSON.parse(userInfo.data.posts[0]['info']) : []

        //Комитим информацию о пользователе
        context.commit('setInfoUser', userInfo.data.posts[0]);

        ///Коммитим обновление активных значений селектов
        //context.dispatch('activeVariablesChange');

        //Коммитим обновление групп пользователя (select)
        //context.dispatch('groupsUserChange');

        //Коммитим обновление интерфейсов пользователя
        //context.dispatch('interfacesUserChange');
      }
    },

    async reqUserLogout(context) {
      //Получаем линки linkBase
      const linkBase = context.getters.linkBaseGet;

      //Получаем линк из массива линков
      const linkLogout = context.getters.linkLogoutGet;

      //Выходим из системы
      const logout = await useAxiosRequest(linkBase + linkLogout, 'POST', '', '', context.getters.nowTokenGet);

      if (logout.status) {
        //Удаляем токен
        context.commit('setNowToken', '');

        //Удаляем пользователя
        context.commit('setNowUser', []);

        //Удаляем ключи из LocalStorage
        useLocalClear(['username', 'password']);

        //Комитим отсутствие авторизации
        context.commit('setUserAuthorized', false);

        //Запрещаем возможность выхода
        context.commit('setLogoutFilled', true);

        //Запрещаем авторизацию
        context.commit('setLoginFilled', true);

        //Перезапускаем стартовые данные
        //context.commit('setStartFilled', false);
      }
      else {
        //Коммитим ошибку и ее текст
        console.log('Ошибка')
      }
    },

    async reqUserUpdate(context) {
      //Получаем линки linkBase
      const linkBase = context.rootState.staticValues.linkBase;

      //Поле 'info' преобразуем в текст
      const data = useFieldText([context.state.infoUser], 'info')

      //Получаем линк данных пользователя
      const linkUser = context.getters.linkUserGet;

      //Формируем параметры запроса
      const params = { id: context.state.infoUser.id}

      //Удаляем атрибуты
      usePropertyDelete([data], ['mode']);

      //Патчим данные пользователя
      const userInfo = await useAxiosRequest(linkBase + linkUser, 'PATCH', data[0], params, context.state.tokenUser);

      //При удачном обновлении данных
      if (userInfo.status) {

        //Текстовое поле 'info' преобразуем в объект
        const infoData = useFieldJson(userInfo.data.posts, 'info')

        //Комитим информацию о пользователе
        context.commit('setInfoUser', infoData[0]);

        ///Коммитим обновление активных значений селектов
        context.dispatch('activeVariablesChange');

        //Коммитим обновление групп пользователя (select)
        context.dispatch('groupsUserChange');

        //Коммитим обновление интерфейсов пользователя
        context.dispatch('interfacesUserChange');
      }
      else {
        //Коммитим ошибку и ее текст
        console.log('Ошибка')
      }
      //Коммитим отсутствие необходимости обновления данных о пользователе
      context.commit('setUpdateFilled', false);
    }
  },
}