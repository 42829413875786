//Возвращаем число допустимых записей на странице:


export default function useSheetLimitation(workBook, objectExcel, keys) {
  let endExcel
  //Проверяем количество полей
  if (keys.length >= objectExcel.fields + 2) {
    return 0
  }
  else {
    //Получаем объект с данными "workBook" страницы "sheetName"
    const objectData = workBook.Sheets[objectExcel.sheet];

    //В объекте "objectData" получаем с ключем '!ref' данные (строку) и разбиваем ее по ":"
    const rangeRef = objectData['!ref'].split(':');

    //В объекте "rangeRef[1]" оставляем только цифры и переводим число соттветствующее макс. строк
    endExcel = Number(rangeRef[1].replace(/[^0-9]/g,''));
  }
  if (endExcel <= objectExcel.records + 1){
    return endExcel
  }
  else {
    return 0
  }
}