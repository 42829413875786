<template>
  <div class="container mt-1">
  <paginate
    :page-count="pageCount"
    :page-range="3"
    :margin-pages="2"
    :click-handler="clickPagination"
    :prev-text="'<'"
    :next-text="'>'"
    :container-class="'pagination'"
    :page-class="'page-item'"
  >
  </paginate>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate-next';

export default {
  components: {
      paginate: Paginate,
  },
  props: {
    pageCount: {
      type: Number,
      required: true,
    }
  },
  setup(props, context) {
    function clickPagination(pageNum) {
      context.emit("changePagination", pageNum)
    }
  return {
    clickPagination
  }
  }
};
</script>

<style scoped>
  .pagination.page-item.active, .pagination.page-item.active {
    background-color: #0a689e;
    border-color: #0a689e;
  }
</style>
