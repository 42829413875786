import { createStore } from 'vuex';
import activeValues from "@/store/modules/ActiveValues.js";
import auxiliaryValues from "@/store/modules/AuxiliaryValues.js";
import basicValues from "@/store/modules/BasicValues.js";
import controlValues from "@/store/modules/ControlValues.js";
import excelValues from "@/store/modules/ExcelValues.js";
import factValues from "@/store/modules/FactValues.js";
import industrialValues from "@/store/modules/IndustrialValues.js";
import infoValues from "@/store/modules/InfoValues.js";
import outsideValues from "@/store/modules/OutsideValues.js";
import pageTools from "@/store/modules/PageTools.js";
import staticValues from "@/store/modules/StaticValues.js";
import taxValues from "@/store/modules/TaxValues.js";
import userValues from "@/store/modules/UserValues.js";


export default createStore({
  modules: {
    activeValues,
    auxiliaryValues,
    basicValues,
    controlValues,
    excelValues,
    factValues,
    industrialValues,
    infoValues,
    outsideValues,
    pageTools,
    staticValues,
    taxValues,
    userValues,
  },
})
