<template>
  <div class="container mt-3">
    <div class="card">
      <h5 class="card-header">{{ post.name }}</h5>
      <div class="card-body">
        <p class="card-text">{{ post.content }}</p>
        <a :href="post.link" class="btn btn-primary" target="_blank">{{ btnText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
    }
  }
}
</script>

<style scoped>
.btn-primary {
  background-color: #0a689e;
  border-color: #0a689e;
}
</style>
