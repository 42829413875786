<template>
  <div>
    <!-- Отображение загруки данных  -->
    <div v-if="!auxiliaryFilled">
      <custom-spinner
       :textSpinner="textLabel.loadAuxiliary[language]"
      >
      </custom-spinner>
    </div>


    <!-- Отображение характеристик для отображения  -->
    <auxiliary-view>
    </auxiliary-view>

    <!-- Отображение раздела сортировки и фильтрации объектов  -->
    <data-view>
    </data-view>

    <!-- Отображение кнопок управления  -->
    <button-view>
    </button-view>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AuxiliaryView from "@/views/AuxiliaryView.vue";
import ButtonView from "@/views/ButtonView.vue";
import CustomSpinner from "@/components/CustomSpinner.vue";
import DataView from "@/views/DataView.vue";


export default {
  components: {
    AuxiliaryView,
    ButtonView,
    CustomSpinner,
    DataView,
  },
  setup() {
    const store = useStore();

    //Инициализируем страницу
    store.commit('setActivePage', 'Auxiliary');

    //Инициализируем модели
    store.commit('setTextModelBase',
                 store.getters.modelsToolsGet[store.getters.activePageGet]['modelBase']);
    store.commit('setTextModelData',
                  store.getters.modelsToolsGet[store.getters.activePageGet]['modelData']);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем подгрузку объектов
    const auxiliaryFilled = computed(() => getAuxiliary(store.getters.auxiliaryFilledGet));

    function getAuxiliary(filled) {
      if (!filled) {
        store.dispatch('reqAuxiliary', 'GET')
      }
      return filled
    }

    return {
      language,
      textLabel,
      auxiliaryFilled
    }
  }
}
</script>
<style scoped>
</style>

