<template>
  <div v-if="loginFilled">
    <custom-spinner
     :textSpinner="textLabel.attemptLogin[language]"
    >
    </custom-spinner>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import CustomSpinner from '@/components/CustomSpinner.vue';

export default {
  components: {
    CustomSpinner,
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем возможность входа
    const loginFilled = computed(() => store.getters.LoginFilledGet);

    //Попытка входа в систему
    store.dispatch('reqUserLogin');

    return {
      language,
      textLabel,
      loginFilled,
    }
  }
}
</script>
<style scoped>
</style>