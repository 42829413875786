<template>
  <div>
    <!-- Отображение загруки объектов -->
    <div v-if="!industrialFilled">
      <custom-spinner
       :textSpinner="textLabel.loadIndustrial[language]"
      >
      </custom-spinner>
    </div>

    <!-- Отображение загруки деклараций -->
    <div v-if="!taxFilled">
      <custom-spinner
       :textSpinner="textLabel.loadFact[language]"
      >
      </custom-spinner>
    </div>

    <!-- Отображение выбора группы объектов -->
    <tax-view>
    </tax-view>

    <!-- Отображение раздела сортировки и фильтрации объектов  -->
    <data-view>
    </data-view>

    <!-- Отображение кнопок  -->
    <snap-view>
    </snap-view>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomSpinner from "@/components/CustomSpinner.vue";
import DataView from "@/views/DataView.vue";
import SnapView from "@/views/SnapView.vue";
import TaxView from "@/views/TaxView.vue";


export default {
  components: {
    CustomSpinner,
    DataView,
    SnapView,
    TaxView,
  },
  setup() {
    const store = useStore();

    //Инициализируем страницу
    store.commit('setActivePage', 'Tax');

    //Инициализируем модели
    store.commit('setTextModelBase',
                 store.getters.modelsToolsGet[store.getters.activePageGet]['modelBase']);
    store.commit('setTextModelData',
                  store.getters.modelsToolsGet[store.getters.activePageGet]['modelData']);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем подгрузку объектов
    const industrialFilled = computed(() => getIndustrial(store.getters.industrialFilledGet));

    //Определяем подгрузку деклараций
    const taxFilled = computed(() => getTax(industrialFilled.value, store.getters.taxFilledGet));

    function getIndustrial(filledIndustrial) {
      if (!filledIndustrial) {
        store.dispatch('reqIndustrial', 'GET')
      }
      return filledIndustrial
    }

    function getTax(filledIndustrial, filledTax) {
      if (filledIndustrial) {
        if (!filledTax) {
          store.dispatch('reqTax', 'GET')
        }
        return filledTax
      }
      else
        return true
    }

    return {
      language,
      textLabel,
      industrialFilled,
      taxFilled
    }
  }
}
</script>
<style scoped>
</style>