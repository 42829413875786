//Возвращает запись из массимва фактических данных

export default function useFilterAuxiliary(posts, post) {
  if (posts.length) {
    const auxiliaryData = posts.filter(function(data) {
          return (
            data.year === post.year &&
            data.variant === post.variant &&
            data.section === post.section &&
            data.code === post.code)
      });
    return auxiliaryData
  }
  else {
    return []
  }

}