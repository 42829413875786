//Возвращает массив заголовков (title) страницы Excel:
// [{"key":"A1", "title":"id", 'primary':"A"}, {"key":B1, "title":"name", 'primary':"B"}]...

export default function useSheetTitles(workBook, sheetName, keys) {
  //Создаем массив с будущими заголовками страницы Excel
  const titles = [];

  //Получаем объект с данными из "workBook" страницы "sheetName"
  const objectData = workBook.Sheets[sheetName];

  //Пробегаемся по переданным ключам: A, B, C, ...
  for (let key of keys) {
    //Если в ключе нет символа "!", выполняем действие
    if (!key.includes('!')) {
      //Если есть ключ в "objectData" : A1, B1, C1, ...
      if (typeof(objectData[key + '1']) !== 'undefined') {
          titles.push({'key': key + '1', 'title': objectData[key + '1']['v'], 'primary': key})
      }
    }
  }
  return titles
}
