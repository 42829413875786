<template>
  <div v-if="excelSheets != 'bad'">
    <xlsx-workbook>
      <xlsx-sheet
        v-for="sheet in excelSheets"
        :collection="sheet.data"
        :key="sheet.name"
        :sheet-name="sheet.name"
      />
      <xlsx-download
        :filename="objExcel.file"
      >
        <button
          type="button"
          class="btn btn-primary btn-sm me-1"
          @click="getData()"
        >{{ textLabel.btnExportName[language] }}
        </button>
      </xlsx-download>
    </xlsx-workbook>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { XlsxWorkbook, XlsxSheet, XlsxDownload }  from "vue3-xlsx";


export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем наименование модели
    const modelText = computed(() => store.getters.textModelDataGet);

    //Инициализируем объект Excel с учетом модели данных
    const objExcel = computed(() => store.getters.excelToolsGet[modelText.value]);

    //Формируем экспортируемые данные
    const excelSheets = ref([]);

    function getData() {
      store.dispatch('useExcelExport');
      excelSheets.value =  store.getters.excelExportGet;
    }

    return {
      language,
      textLabel,
      objExcel,
      excelSheets,
      getData
    };
  }
};
</script>

<style scoped>
.btn-primary {
  background-color: #0a689e;
  border-color: #0a689e;
}
</style>
