<template>
  <div v-if="textLabel != 'bad'">
    <div class="container mt-3">
      <div class="row">
        <div class="col-10">
          <div v-if="group != 'bad'">
            <custom-select
             v-model="groupActive"
             :disabledValue="group.forbidden[language]"
             :selectOptions="group.params"
             :language="language"
             @customOption="groupChange"
            >
            </custom-select>
          </div>
        </div>
        <div class="col-2">
          <div v-if="bring != 'bad'">
            <custom-select
             v-model="bringActive"
             :disabledValue="bring.forbidden[language]"
             :selectOptions="bring.params"
             :language="language"
             @customOption="bringChange"
            >
            </custom-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed  } from 'vue';
import { useStore } from 'vuex';
import CustomSelect from "@/components/CustomSelect.vue";


export default {
  components: {
    CustomSelect,
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определение режима сохранения данных
    //const activeSaved = computed(() => store.getters.activeSavingGet);

    //Определение селекта группы пользователя
    const group = computed(() => store.getters.selectGroupGet);
    const groupActive = computed(() => store.getters.activeGroupGet);

    //Определение селекта ввод/экспорт данных
    const bring = computed(() => store.getters.selectBringGet);
    const bringActive = computed(() => store.getters.activeBringGet);

    function groupChange(data) {
      store.commit('setActiveGroup', data);
      store.dispatch('changeGroup');
    }

    function bringChange(data) {
      store.commit('setActiveBring', data);
    }


    return {
      language,
      textLabel,

      group,
      groupActive,
      groupChange,

      bring,
      bringActive,
      bringChange,

    }
  }
}
</script>
<style scoped>
</style>
