<template>
  <div class="container mt-5">
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <form @submit.prevent="updateUser">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                 class="accordion-button collapsed"
                 type="button"
                 data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseOne"
                 aria-expanded="false"
                 aria-controls="flush-collapseOne"
                >
                 <b>{{ textLabel.titleAccordionPersonalData[language] }}</b>
                </button>
              </h2>
              <div id="flush-collapseOne"
               class="accordion-collapse collapse"
               data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <div class="mb-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" id="addon-wrapping">{{ textLabel.labLastName[language] }}</span>
                      <custom-input
                       type="text"
                       v-model="form.last_name.value"
                       @blur="form.last_name.blur"
                       aria-describedby="addon-wrapping"
                      >
                     </custom-input>
                    </div>
                    <small v-if="form.last_name.touched && form.last_name.errors.required" class="invalid">
                     {{ textLabel.txtFieldRequired[language] }}
                    </small>
                    <small v-else-if="form.last_name.touched && form.last_name.errors.maxLength" class="invalid">
                     {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxLastName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.last_name.value.length }}.
                    </small>
                  </div>
                  <div class="mb-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" id="addon-wrapping">{{ textLabel.labFirstName[language] }}</span>
                      <custom-input
                       type="text"
                       v-model="form.first_name.value"
                       @blur="form.first_name.blur"
                       aria-describedby="addon-wrapping"
                      >
                     </custom-input>
                    </div>
                    <small v-if="form.first_name.touched && form.first_name.errors.required" class="invalid">
                     {{ textLabel.txtFieldRequired[language] }}
                    </small>
                    <small v-else-if="form.first_name.touched && form.first_name.errors.maxLength" class="invalid">
                     {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxFirstName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.first_name.value.length }}.
                    </small>
                  </div>
                  <div class="mb-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" id="addon-wrapping">{{ textLabel.labBusinessName[language] }}</span>
                      <custom-input
                       type="text"
                       v-model="form.business.value"
                       @blur="form.business.blur"
                       aria-describedby="addon-wrapping"
                      >
                     </custom-input>
                    </div>
                    <small v-if="form.business.touched && form.business.errors.required" class="invalid">
                     {{ textLabel.txtFieldRequired[language] }}
                    </small>
                    <small v-else-if="form.business.touched && form.business.errors.minLength" class="invalid">
                      {{ textLabel.txtMinNumber[language] }} - {{ textLabel.maxBusinessName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.business.value.length }}.
                    </small>
                    <small v-else-if="form.business.touched && form.business.errors.maxLength" class="invalid">
                     {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxBusinessName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.business.value.length }}.
                    </small>
                  </div>
                  <div class="mb-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" id="addon-wrapping">{{ textLabel.labPhoneName[language] }}</span>
                      <custom-input
                       type="text"
                       v-model="form.phone.value"
                       @blur="form.phone.blur"
                       aria-describedby="addon-wrapping"
                      >
                     </custom-input>
                    </div>
                    <small v-if="form.phone.touched && form.phone.errors.required" class="invalid">
                        {{ textLabel.txtFieldRequired[language] }}
                    </small>
                    <small v-else-if="form.phone.touched && form.phone.errors.maxLength" class="invalid">
                        {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxPhoneName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.phone.value.length }}.
                    </small>
                  </div>
                  <div class="mb-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" id="addon-wrapping">{{ textLabel.labWatchAppName[language] }}</span>
                      <custom-input
                       type="text"
                       v-model="form.whatsapp.value"
                       @blur="form.whatsapp.blur"
                       aria-describedby="addon-wrapping"
                      >
                     </custom-input>
                    </div>
                    <small v-if="form.whatsapp.touched && form.whatsapp.errors.required" class="invalid">
                        {{ textLabel.txtFieldRequired[language] }}
                    </small>
                    <small v-else-if="form.whatsapp.touched && form.whatsapp.errors.maxLength" class="invalid">
                        {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxWatchAppName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.whatsapp.value.length }}.
                    </small>
                  </div>
                  <div class="mb-1">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" id="addon-wrapping">{{ textLabel.labEmailName[language] }}</span>
                      <custom-input
                       type="text"
                       v-model="form.email.value"
                       @blur="form.email.blur"
                       aria-describedby="addon-wrapping"
                      >
                     </custom-input>
                    </div>
                    <small v-if="form.email.touched && form.email.errors.required" class="invalid">
                        {{ textLabel.txtFieldRequired[language] }}
                    </small>
                    <small v-else-if="form.email.touched && form.email.errors.maxLength" class="invalid">
                        {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxEmailName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.email.value.length }}.
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item mt-2">
              <h2 class="accordion-header">
                <button
                 class="accordion-button collapsed"
                 type="button"
                 data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseTwo"
                 aria-expanded="false"
                 aria-controls="flush-collapseTwo"
                >
                 <b>{{ textLabel.titleAccordionGroups[language] }}</b>
                </button>
              </h2>
              <div id="flush-collapseTwo"
               class="accordion-collapse collapse"
               data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <div class="mb-3">
                    <language-table
                     :headerTable="header"
                     :recordsTable="groupsUser.params"
                     :language="language"
                     @clickTable="takeID"
                    >
                    </language-table>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item mt-2">
              <h2 class="accordion-header">
                <button
                 class="accordion-button collapsed"
                 type="button"
                 data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseThree"
                 aria-expanded="false"
                 aria-controls="flush-collapseThree"
                >
                 <b>{{ textLabel.titleAccordionFields[language] }}</b>
                </button>
              </h2>
              <div id="flush-collapseThree"
               class="accordion-collapse collapse"
               data-bs-parent="#accordionFlushExample">
                <div class="accordion-body myClass">
                  <div class="mt-1">
                    <!-- Выбор таблицы  -->
                    <custom-select
                     v-model="tableActive"
                     :disabledValue="tableForbidden[language]"
                     :selectOptions="tableParams"
                     :language="language"
                     @customOption="tableChange"
                    >
                    </custom-select>
                    <div class="mt-3">
                      <ol>
                        <li v-for="(field, index) in showFields" :key="index">
                          <input type="checkbox" class="me-2"
                           :id="field.name"
                           :value="field.name"
                           v-model="field.visible"
                          >
                          <label :for="field.name">{{ field.title[language] }}</label>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item mt-2">
              <h2 class="accordion-header">
                <button
                 class="accordion-button collapsed"
                 type="button"
                 data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseFour"
                 aria-expanded="false"
                 aria-controls="flush-collapseFour"
                >
                 <b>{{ textLabel.titleAccordionDetails[language] }}</b>
                </button>
              </h2>
              <div id="flush-collapseFour"
               class="accordion-collapse collapse"
               data-bs-parent="#accordionFlushExample">
                <div class="accordion-body myClass">

                  <!-- Дополнительные настройки  -->
                  <div class="mt-1">

                    <!-- Язык  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ speech.forbidden[language] }}</span>
                        <custom-select
                         v-model="speechActive"
                         :disabledValue="speech.forbidden[language]"
                         :selectOptions="speech.params"
                         :language="language"
                         @customOption="speechChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Группа  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ group.forbidden[language] }}</span>
                        <custom-select
                         v-model="groupActive"
                         :disabledValue="group.forbidden[language]"
                         :selectOptions="group.params"
                         :language="language"
                         @customOption="groupChange"
                        >
                        </custom-select>
                      </div>
                    </div>


                    <!-- Год  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ year.forbidden[language] }}</span>
                        <custom-select
                         v-model="yearActive"
                         :disabledValue="year.forbidden[language]"
                         :selectOptions="year.params"
                         :language="language"
                         @customOption="yearChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Квартал  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ quarter.forbidden[language] }}</span>
                        <custom-select
                         v-model="quarterActive"
                         :disabledValue="quarter.forbidden[language]"
                         :selectOptions="quarter.params"
                         :language="language"
                         @customOption="quarterChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Специфика  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ specific.forbidden[language] }}</span>
                        <custom-select
                         v-model="specificActive"
                         :disabledValue="specific.forbidden[language]"
                         :selectOptions="specific.params"
                         :language="language"
                         @customOption="specificChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Вариант  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ variant.forbidden[language] }}</span>
                        <custom-select
                         v-model="variantActive"
                         :disabledValue="variant.forbidden[language]"
                         :selectOptions="variant.params"
                         :language="language"
                         @customOption="variantChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Модели-характеристики  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ sign.forbidden[language] }}</span>
                        <custom-select
                         v-model="signActive"
                         :disabledValue="sign.forbidden[language]"
                         :selectOptions="sign.params"
                         :language="language"
                         @customOption="signChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Направление сортировки  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ direct.forbidden[language] }}</span>
                        <custom-select
                         v-model="directActive"
                         :disabledValue="direct.forbidden[language]"
                         :selectOptions="direct.params"
                         :language="language"
                         @customOption="directChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Интерфейс  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ face.forbidden[language] }}</span>
                        <custom-select
                         v-model="faceActive"
                         :disabledValue="face.forbidden[language]"
                         :selectOptions="face.params"
                         :language="language"
                         @customOption="faceChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Ввод-Экспорт  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ bring.forbidden[language] }}</span>
                        <custom-select
                         v-model="bringActive"
                         :disabledValue="bring.forbidden[language]"
                         :selectOptions="bring.params"
                         :language="language"
                         @customOption="bringChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Действие  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ action.forbidden[language] }}</span>
                        <custom-select
                         v-model="actionActive"
                         :disabledValue="action.forbidden[language]"
                         :selectOptions="action.params"
                         :language="language"
                         @customOption="actionChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Сортировка ресурсов  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ outside.forbidden[language] }}</span>
                        <custom-select
                         v-model="outsideActive"
                         :disabledValue="outside.forbidden[language]"
                         :selectOptions="outside.params"
                         :language="language"
                         @customOption="outsideChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Релизы ФНО 870  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ release.forbidden[language] }}</span>
                        <custom-select
                         v-model="releaseActive"
                         :disabledValue="release.forbidden[language]"
                         :selectOptions="release.params"
                         :language="language"
                         @customOption="releaseChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Задекржка в ошибках  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ defective.forbidden[language] }}</span>
                        <custom-select
                         v-model="defectiveActive"
                         :disabledValue="defective.forbidden[language]"
                         :selectOptions="defective.params"
                         :language="language"
                         @customOption="defectiveChange"
                        >
                        </custom-select>
                      </div>
                    </div>

                    <!-- Режим сохранения  -->
                    <div class="mt-1">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">{{ saving.forbidden[language] }}</span>
                        <custom-select
                         v-model="savingActive"
                         :disabledValue="saving.forbidden[language]"
                         :selectOptions="saving.params"
                         :language="language"
                         @customOption="savingChange"
                        >
                        </custom-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <custom-button type="submit" :disabled="!form.valid">
               {{ textLabel.btnSaveName[language] }}
              </custom-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import CustomInput from '@/components/CustomInput.vue';
import CustomButton from '@/components/CustomButton.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import LanguageTable from '@/components/LanguageTable.vue';
import { useFormControl } from '@/controls/FormControl.js';
import useArrayFiltered from '@/workers/ArrayFiltered.js';
import useArraySorted from '@/workers/ArraySorted.js';
import useParamsInterface from '@/workers/ParamsInterface.js';

const required = val => !!val;
const minLength = num => val => val.length >= num;
const maxLength = num => val => val.length <= num;


export default {
  components: {
    CustomInput,
    CustomButton,
    CustomSelect,
    LanguageTable,
  },
  setup() {
    const store = useStore();

    //Инициализируем страницу
    store.commit('setActivePage', 'Tool');

    //Инициализируем модели
    store.commit('setTextModelBase',
                 store.getters.modelsToolsGet[store.getters.activePageGet]['modelBase']);
    store.commit('setTextModelData',
                  store.getters.modelsToolsGet[store.getters.activePageGet]['modelData']);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Данные о пользователе
    const infoUser = ref(store.getters.infoUserGet);

    //Интерфейсы пользователя
    const facesUser = (
      infoUser.value.info.facesUser ? ref(infoUser.value.info.facesUser) : ref(store.getters.interfacesBasicGet)
    );

    //Группы пользователя
    const groupsUser = (
     infoUser.value.info.groupsUserGet ?  ref(infoUser.value.info.groupsUser) : ref(store.getters.selectGroupGet)
    );

    //Селекты пользователя
    const selectsUser = (
     infoUser.value.info.selectsUser ?  ref(infoUser.value.info.selectsUser) : ref(getSelectsUser(store.getters.selectsDefaultGet))
    );

    //Текущий пост
    const postNow = ref([]);

    //Форма персональных данных
    const form = useFormControl({
      last_name: {
        value: infoUser.value.last_name,
        validators: {
          required,
          maxLength: maxLength(textLabel.value.maxLastName[language.value])
        }
      },
      first_name: {
        value: infoUser.value.first_name,
        validators: {
          required,
          maxLength: maxLength(textLabel.value.maxFirstName[language.value])
        }
      },
      business: {
        value: infoUser.value.business,
        validators: {
          required,
          minLength: minLength(textLabel.value.minBusinessName[language.value]),
          maxLength: maxLength(textLabel.value.maxBusinessName[language.value])
        }
      },
      phone: {
        value: infoUser.value.phone,
        validators: {
          required,
          maxLength: maxLength(textLabel.value.maxPhoneName[language.value])
        }
      },
      whatsapp: {
        value: infoUser.value.whatsapp,
        validators: {
          required,
          maxLength: maxLength(textLabel.value.maxWatchAppName[language.value])
        }
      },
      email: {
        value: infoUser.value.email,
        validators: {
          required,
          maxLength: maxLength(textLabel.value.maxEmailName[language.value])
        }
      }
    })

    const header = computed(() => [{"name":"value", "title":textLabel.value.titleFieldGroupTitle}]);

    //Формируем селект таблиц
    const selectTable = computed(
    () => useParamsInterface(facesUser.value, 'Table', 1, textLabel.value.titleForbiddenTable)
    );

    //Селект выбора таблицы
    const tableParams = computed(() => selectTable.value.params);

    const tableForbidden = computed(() => selectTable.value.forbidden);
    const tableActive = ref(selectTable.value['default']);

    //Формируем отображаемые поля
    //const showFields = ref(interfaces.value[tableActive.value]['info']['params']['personal']);
    const showFields = ref([]);

    //Селект - Язык
    const speech = addParam(store.getters.selectSpeechGet);
    const speechActive = ref(selectsUser.value.activeLanguage.value);

    //Селект - Группы пользователя
    const group = computed(() => addParam(groupsUser.value));
    const groupActive = ref(selectsUser.value.activeGroup.value);

    //Селект - Год
    const year = addParam(store.getters.selectYearGet);
    const yearActive = ref(selectsUser.value.activeYear.value);

    //Селект - Квартал
    const quarter = addParam(store.getters.selectQuarterGet);
    const quarterActive = ref(selectsUser.value.activeQuarter.value);

    //Селект - Тип
    const specific = addParam(store.getters.selectSpecificGet);
    const specificActive = ref(selectsUser.value.activeSpecific.value);

    //Селект - Вариант
    const variant = addParam(store.getters.selectVariantGet);
    const variantActive = ref(selectsUser.value.activeVariant.value);

    //Селект - Характеристики
    const sign = addParam(store.getters.selectSignGet);
    const signActive = ref(selectsUser.value.activeSign.value);

    //Селект - Направление сортировки
    const direct = addParam(store.getters.selectDirectGet);
    const directActive = ref(selectsUser.value.activeDirect.value);

    //Селект - Интерфейс
    const face = addParam(store.getters.selectInterfaceGet);
    const faceActive = ref(selectsUser.value.activeFace.value);

    //Селект - Ввод/Экспорт данных
    const bring = addParam(store.getters.selectBringGet);
    const bringActive = ref(selectsUser.value.activeBring.value);

    //Селект - Действие
    const action =  addParam(store.getters.selectActionGet);
    const actionActive = ref(selectsUser.value.activeAction.value);

    //Селект - Сортировка ресурсов
    const outside =  addParam(store.getters.selectOutsideGet);
    const outsideActive = ref(selectsUser.value.activeOutside.value);

    //Селект - Число постов
    //const counter =  addParam(store.getters.selectCountPostGet);
    //const counterActive = ref(selectsUser.value.activeCounter.value);

    //Селект - Релизы ФНО870
    const release =  addParam(store.getters.selectTRF870Get);
    const releaseActive = ref(selectsUser.value.activeRelease.value);

    //Селект - Отображения ошибки
    const defective =  addParam(store.getters.selectDefectiveGet);
    const defectiveActive = ref(selectsUser.value.activeDefective.value);

    //Селект - Сохранение данных
    const saving =  addParam(store.getters.selectSavingGet);
    const savingActive = ref(selectsUser.value.activeSaving.value);

    function updateUser() {
      infoUser.value.last_name = form.last_name.value;
      infoUser.value.first_name = form.first_name.value;
      infoUser.value.business = form.business.value;
      infoUser.value.phone = form.phone.value;
      infoUser.value.whatsapp = form.whatsapp.value;
      infoUser.value.email = form.email.value;

      infoUser.value.info = {
       'groupsUser': groupsUser.value,
       'facesUser': facesUser.value,
       'selectsUser': selectsUser.value,
      }

      //Коммитим пользователя
      store.commit('setInfoUser', infoUser.value);

      //Сохраняем обновления пользователя
      store.dispatch('reqUserUpdate');
    }

    function getSelectsUser(obj) {
      //Формируем на основе шаблона
      let objNew = {};
      const keys = Object.keys(obj)
      if (keys.length) {
        for (let key of keys) {
          if (obj[key]['take']) {
            objNew[key] = obj[key];
            objNew[key]['value'] = 'default';
          }
        }
      }
      return JSON.parse(JSON.stringify(objNew))
    }

    function tableChange(data) {
      tableActive.value = data;
      showFields.value = facesUser.value[tableActive.value]['info']['params']['personal'];
    }

    function takeID(id) {
      console.log('id', id)
    }

    function addParam(obj) {
      if (obj) {
        const newObject = JSON.parse(JSON.stringify(obj));
        newObject.params.push(textLabel.value['addSelect']);
        newObject.params = useArraySorted(newObject.params, 'sort', 'direct')
        return newObject
      }
      else {
        return obj
      }
    }


    function speechChange(data) {
      selectsUser.value.activeLanguage.value = data
    }

    function groupChange(data) {
      selectsUser.value.activeGroup.value = data
    }

    function yearChange(data) {
      selectsUser.value.activeYear.value = data
    }

    function quarterChange(data) {
      selectsUser.value.activeQuarter.value = data
    }

    function specificChange(data) {
      selectsUser.value.activeSpecific.value = data
    }

    function variantChange(data) {
      selectsUser.value.activeVariant.value = data
    }

    function signChange(data) {
      selectsUser.value.activeSign.value = data
    }

    function directChange(data) {
      selectsUser.value.activeDirect.value = data
    }

    function faceChange(data) {
      selectsUser.value.activeFace.value = data
    }

    function bringChange(data) {
      selectsUser.value.activeBring.value = data
    }

    function actionChange(data) {
      selectsUser.value.activeAction.value = data
    }

    function outsideChange(data) {
      selectsUser.value.activeOutside.value = data
    }

    function releaseChange(data) {
      selectsUser.value.activeRelease.value = data
    }

    function defectiveChange(data) {
      selectsUser.value.activeDefective.value = data
    }

     function savingChange(data) {
      selectsUser.value.activeSaving.value = data
    }


    return {
      language,
      textLabel,

      form,
      updateUser,
      header,

      groupsUser,

      showFields,

      takeID,

      tableParams,
      tableForbidden,
      tableActive,
      tableChange,

      postNow,

      speechActive,
      speech,
      speechChange,

      groupActive,
      group,
      groupChange,

      yearActive,
      year,
      yearChange,

      quarterActive,
      quarter,
      quarterChange,

      specificActive,
      specific,
      specificChange,

      variantActive,
      variant,
      variantChange,

      signActive,
      sign,
      signChange,

      directActive,
      direct,
      directChange,

      faceActive,
      face,
      faceChange,

      bringActive,
      bring,
      bringChange,

      actionActive,
      action,
      actionChange,

      outsideActive,
      outside,
      outsideChange,

      releaseActive,
      release,
      releaseChange,

      defectiveActive,
      defective,
      defectiveChange,

      savingActive,
      saving,
      savingChange,
    }
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0.1rem
}
.contacts {
  color: #0a689e;
}
h5 {
  color: red;
}
.accordion-button {
   padding-top: 0.25rem;
   padding-right: 0.25rem;
   padding-bottom: 0.25rem;
   padding-left: 0.25rem;
   color: #0a689e;
}
.accordion-button:not(.collapsed) {
    color: #0a689e;
    background-color: #fff;
}

.accordion-body {
   padding-top: 0.25rem;
   padding-right: 0.25rem;
   padding-bottom: 0.25rem;
   padding-left: 0.25rem;
}
.myClass {
  color: #0a689e;
}
.myBlack {
  color: black;
}
.invalid {
  color: red;
}
</style>

