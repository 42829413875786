//Возвращает массив данных страницы Excel

export default function useSheetValues(workBook, sheetName, records, recCount) {
  //Создаем массив с данными
  const values = [];

  //Получаем объект с данными "workBook" страницы "sheetName"
  const objectData = workBook.Sheets[sheetName];

  //Создаем переменную с начальным значением 2 - брать данные со второй строки в Excel
  let nowExcel = 2;
  let obj;
  for (nowExcel; nowExcel <= recCount; nowExcel++) {
    obj = {}
    for (let record of records) {
      obj[record['name']+'_primary'] = record['primary'] + String(nowExcel);
      if (typeof(objectData[record['primary'] + String(nowExcel)]) !== 'undefined') {
          obj[record['name']+'_value'] = objectData[record['primary'] + String(nowExcel)]['v'];
          obj[record['name']+'_type'] = objectData[record['primary'] + String(nowExcel)]['t'];
      }
    }
    values.push(obj);
  }
  return values
}
