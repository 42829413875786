export default function useSheetCreate(modelData, dataBase) {
  const sheet = []
  let obj = {}
  let val = ''
  //Получаем ключи (поля) из modelData
  const fields = Object.keys(modelData);

  if (dataBase.length) {
    for (let post of dataBase) {
      obj = {}
      for (let field of fields) {
        val = post[modelData[field]['name'] + '_value']
        if (typeof(val) !== 'undefined') {
          if (typeof(val) === 'string') {
            if (val[0] === '~') {
              val = val.slice(1)
            }
          }
          obj[modelData[field]['name']] = val
        }
      }
      sheet.push(obj)
    }
  }
  else {
    obj = {}
      for (let field of fields) {
          obj[modelData[field]['name']] = modelData[field]['default']
      }
      sheet.push(obj)
  }

  return sheet
}
