//Возвращает результат "axios"-запроса

import axios from "axios";
import CSRF_TOKEN from "@/workers/CsrfToken.js";


export default async function useAxiosRequest(url, method, data, params, token) {
   const config = {
    "method": method,
    "data": data ? data : undefined,
    "params": params ? params : undefined,
    "headers": {
      "Content-Type": "application/json",
      "X-CSRFToken": CSRF_TOKEN,
      "Authorization": token ? 'Token '+ token : undefined,
    }
  }
   try {
    const response = await axios(url, config);
    //console.log(response)
    return response
   } catch (error) {
    return error
  }
}
