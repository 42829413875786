<template>
  <select
    class="form-select myClass"
    v-model="modelSelect"
    @change="changeOption()"
    :key="keySelect"
  >
    <option disabled value="">{{ disabledValue }}</option>
      <option class="myClass"
        v-for="option in selectOptions"
        :key="option.id"
        :value="option.name"
      >
        {{ option.value[language] }}
      </option>
  </select>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    modelValue: {
      type: [String, Number]
    },
    disabledValue: {
      type: String
    },
    selectOptions: {
      type: Array
    },
    language: {
      type: String
    },
    keySelect: {
      type: String,
      default: '0'
    }
  },
  setup(props, context) {
    const modelSelect = ref(props.modelValue);

    function changeOption() {
      context.emit("customOption", event.target.value)
    }

    return {
      modelSelect,
      changeOption
    }
  }
}
</script>

<style scoped>
.myClass {
  color: #0a689e;
  cursor: pointer;
}
</style>
