<template>
  <button
    class="btn btn-primary btn-sm me-1 mb-1"
  >
  <slot></slot>
  </button>
</template>

<script>

export default {
  setup() {
    return {
    }
  }
}
</script>

<style scoped>
.btn-primary {
    background-color: #0a689e;
    border-color: #0a689e;
}
</style>
