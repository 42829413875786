<template>
  <div class="modal"
   id="modalChangeData"
   tabindex="-1"
   aria-labelledby="exampleChangeData"
   aria-hidden="true"
  >
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header bg-secondary text-white">
        <div class="modal-title"
         id="exampleChangeData"
        >
         {{ textLabel.nameFormChangeData[language] }}
        </div>
        <button
         type="button"
         class="btn-close"
         @click="$emit('cancelModal')"
         aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group flex-nowrap mt-1" v-for="(field, index) in fields" :key="index">
          <span class="input-group-text" id="addon-wrapping">{{field.title[language] }}</span>
          <custom-input
            type="text"
            :placeholder="field.default[language]"
            v-model="field.value"
            aria-describedby="addon-wrapping"
          >
          </custom-input>
        </div>

      </div>
      <div class="modal-footer bg-secondary text-white">
        <custom-button
         @click="$emit('confirmModal', fields)"
        >
          {{ textLabel.btnContinueName[language] }}
        </custom-button>
        <custom-button
         @click="$emit('cancelModal')"
        >
          {{ textLabel.btnCancelName[language] }}
        </custom-button>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import CustomInput from '@/components/CustomInput';


export default {
  components: {
    CustomButton,
    CustomInput,
  },
  props: {
    textLabel: {
      type: Object,
    },
    language: {
      type: String,
    },
    fields: {
      type: Array,
    },
  },
  setup() {

    return {}
  }
}
</script>

<style scoped>
.btn-primary {
  color: #fff;
  background-color: #0a689e;
  border-color: #0a689e;
}

.modal-header {
  padding: 0.4rem;
}

.modal-footer {
  padding: 0.1rem;
}

.form-control {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.input-group-text {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

</style>
