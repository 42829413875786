<template>
  <select
    class="myClass"
    v-model="modelSelect"
    @change="changeOption()"
    :key="keyNow"
  >
      <option class="myClass"
        v-for="option in selectOptions"
        :key="option.id"
        :value="option.name"
      >
        {{ option.title }}
      </option>
  </select>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    modelValue: {
      type: [String, Number]
    },
    selectOptions: {
      type: Array
    },
    keyNow: {
      type: Number,
      default: 0
    }
  },
  setup(props, context) {
    const modelSelect = ref(props.modelValue);

    function changeOption() {
      context.emit("customOption", event.target.value)
    }

    return {
      modelSelect,
      changeOption
    }
  }
}
</script>

<style scoped>
.myClass {
  color: #0a689e;
  cursor: pointer;
  border-radius: 12%;
}
</style>
