import {createRouter, createWebHistory} from "vue-router";
import MainPage from "@/pages/MainPage.vue";
import OutsidePage from "@/pages/OutsidePage.vue";
import DataPage from "@/pages/DataPage.vue";
import ReportPage from "@/pages/ReportPage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import CabinetPage from "@/pages/CabinetPage.vue";


const routes = [
    {
        path: '/',
        name: 'main',
        component: MainPage,
    },
    {
        path: '/sources/:slug',
        name: 'sources',
        component: OutsidePage,
    },
    {
        path: '/data/:slug',
        name: 'data',
        component: DataPage,
    },
    {
        path: '/reports/:slug',
        name: 'reports',
        component: ReportPage,
    },
    {
        path: '/about/:slug',
        name: 'about',
        component: AboutPage,
    },
    {
        path: '/cabinet/:slug',
        name: 'cabinet',
        component: CabinetPage,
    },
]

const router = createRouter({
    routes,
    history: createWebHistory()
})

export default router;
