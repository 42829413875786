import useArrayFiltered from '@/workers/ArrayFiltered.js';
import useArrayOfObject from '@/workers/ArrayOfObject.js';
import useFieldsForm from '@/workers/FieldsForm.js';
import useFilterAuxiliary from '@/workers/FilterAuxiliary.js';
import useFilterFact from '@/workers/FilterFact.js';


export default {
  state: {

    //Статус диалогового окна "фильтрация объектов"
    filteredObject : false,

    //Статус диалогового окна "добавить данные"
    addedData : false,

    //Статус диалогового окна "удалить данные"
    deletedData : false,

    //Статус диалогового окна "сохранить данные"
    savedData : false,

    //Статус диалогового окна "обновить данные"
    updatedData : false,

    //Статус диалогового окна "обобщить данные"
    reportedData : false,

    //Текущий пост данных
    postCurrent : [],

    //Текущие объекты
    groupCurrent : [],

  },

  getters: {

    dlgStatusGet(state, getters) {
      if (!getters.filteredObjectGet &&
          !getters.addedDataGet &&
          !getters.deletedDataGet &&
          !getters.savedDataGet &&
          !getters.updatedDataGet &&
          !getters.reportedDataGet) {
       return false
      }
      return true
    },

    //Получаем статус окна "фильтрация объектов"
    filteredObjectGet(state) {
      return state.filteredObject
    },

    //Получаем статус окна "добавить данные"
    addedDataGet(state) {
      return state.addedData
    },

    //Получаем статус окна "удалить данные"
    deletedDataGet(state) {
      return state.deletedData
    },

    //Получаем статус окна "сохранить данные"
    savedDataGet(state) {
      return state.savedData
    },

    //Получаем статус окна "обновить данные"
    updatedDataGet(state) {
      return state.updatedData
    },

    //Получаем статус окна "обобщить данные"
    reportedDataGet(state) {
      return state.reportedData
    },

    //Получаем текущий пост данных
    postCurrentGet(state) {
      return state.postCurrent
    },

    //Получаем текущие объекты
    groupCurrentGet(state) {
      return state.groupCurrent
    },

  },

  mutations: {

    //Изменяем статус окна "фильтрация объектов"
    setFilteredObject(state, bool) {
      state.filteredObject = bool
    },

    //Изменяем статус окна "добавить данные"
    setAddedData(state, bool) {
      state.addedData = bool
    },

    //Изменяем статус окна "удалить данные"
    setDeletedData(state, bool) {
      state.deletedData = bool
    },

    //Изменяем статус окна "сохранить данные"
    setSavedData(state, bool) {
      state.savedData = bool
    },

    //Изменяем статус окна "обновить данные"
    setUpdatedData(state, bool) {
      state.updatedData = bool
    },

    //Изменяем статус окна "обобщить данные"
    setReportedData(state, bool) {
      state.reportedData = bool
    },

    //Изменяем текущий пост
    setPostCurrent(state, post) {
      state.postCurrent = post
    },

    //Изменяем текущие объекты
    setGroupCurrent(state, group) {
      state.groupCurrent = group
    },

  },

  actions: {

    beforeFilterObject(context, active) {
      if (active) {
        const groupCurrent = JSON.parse(JSON.stringify(context.getters.industrialDataGet));
        context.commit('setGroupCurrent', groupCurrent)
        context.commit('setFilteredObject', true)
      }
      else {
        context.commit('setFilteredObject', false)
      }
    },

    afterFilterObject(context, value) {
      const groupCurrent = JSON.parse(JSON.stringify(value));
      context.commit('setIndustrialData', groupCurrent);
    },

    beforeAddData(context, active) {
      if (active) {
        const postCurrent = useFieldsForm(context.getters.activeModelDataGet['info']['params'],
                                          [],
                                          context.getters.activeBringGet,
                                          context.getters.statusWorkerGet);
        context.commit('setPostCurrent', postCurrent)
        context.commit('setAddedData', true)
      }
      else {
        context.commit('setAddedData', false)
      }
    },

    afterAddData(context) {

    },

    beforeDeleteData(context, active) {
      if (active) {
        context.commit('setDeletedData', true)
      }
      else {
        context.commit('setDeletedData', false)
      }
    },

    afterDeleteData(context) {
      if (context.getters.textModelBaseGet === 'modelIndustrial') {
        for (let record of context.getters.expoDataGet) {
          const industrialData = useArrayFiltered(context.getters.industrialDataGet, 'id', record.id)
          if (industrialData.length === 1) {
              industrialData[0].status = -1;
              industrialData[0].saved = 'no';
          }
        }
        const industrialDataNew = context.getters.industrialDataGet.filter(function(data) {
          return (data.id > 0 || data.status > 0)});
        context.commit('setIndustrialData', JSON.parse(JSON.stringify(industrialDataNew)));
      }

      if (context.getters.textModelBaseGet === 'modelFact') {
        const factObject = JSON.parse(JSON.stringify(context.getters.factObjectGet));
        for (let record of context.getters.expoDataGet) {
          factObject.industrial = record.industrial;
          const recNowFact = useFilterFact(context.getters.factDataGet, factObject);
          if (recNowFact.length === 1) {
            recNowFact[0].info = []
            recNowFact[0].saved = 'no'
          }
        }
      }

      if (context.getters.textModelBaseGet === 'modelAuxiliary') {
        //Создаем запись служебных данных на основе шаблона
        const auxiliaryObject = JSON.parse(JSON.stringify(context.getters.auxiliaryObjectGet));

        //Проверяем наличие этой записи в общих данных
        const recNowAuxiliary = useFilterAuxiliary(context.getters.auxiliaryDataGet, auxiliaryObject);
        if (recNowAuxiliary.length === 1) {
            recNowAuxiliary[0].info = []
            recNowAuxiliary[0].saved = 'no'
        }
      }
    },

    beforeSaveData(context, active) {
      active ? context.commit('setSavedData', true) : context.commit('setSavedData', false)
    },

    afterSaveData(context, value) {
      value ? context.dispatch('saveChangeData') :  context.dispatch('ignoreChangeData')
    },

    beforeUpdateData(context) {

    },

    afterUpdateData(context) {

    },

    beforeReportData(context) {

    },

    afterReportData(context) {

    },

    saveChangeData(context) {
      if (context.getters.textModelBaseGet === 'modelIndustrial') {
        context.dispatch('reqIndustrial', 'PATCH')
      }

      if (context.getters.textModelBaseGet === 'modelFact') {
        console.log('factDataGet', context.getters.factDataGet)
        context.dispatch('reqFact', 'PATCH')
      }

      if (context.getters.textModelBaseGet === 'modelTax') {
        context.dispatch('reqTax', 'PATCH')
      }

      if (context.getters.textModelBaseGet === 'modelAuxiliary') {
        context.dispatch('reqAuxiliary', 'PATCH')
      }
    },

    ignoreChangeData(context) {
      if (context.getters.textModelBaseGet === 'modelIndustrial') {
        context.dispatch('reqIndustrial', 'GET')
      }

      if (context.getters.textModelBaseGet === 'modelFact') {
        context.dispatch('reqFact', 'GET')
      }

      if (context.getters.textModelBaseGet === 'modelAuxiliary') {
        context.dispatch('reqAuxiliary', 'GET')
      }
    },

    dblClickTable(context, idRecord) {
      console.log('idRecord', idRecord)
      console.log('model', context.getters.textModelBaseGet)
      console.log('action', context.getters.activeActionGet)
    },

    addToTable(context, value, toArray=0) {

      //Унифицируем данные при добавлении или импорте
      const postNow = toArray ? value : useArrayOfObject(value);

      console.log('postNow', postNow)

      //Создаем объект данных
      const obj = {};
      for (let field of postNow) {
        obj[field.name] = field.value
      }

      //Добавляем или редактируем auxiliary
      if (context.getters.textModelBaseGet === 'modelAuxiliary') {
        //Создаем запись служебных данных на основе шаблона
        const auxiliaryObject = JSON.parse(JSON.stringify(context.getters.auxiliaryObjectGet));

        //Проверяем наличие этой записи в общих данных
        const recNowAuxiliary = useFilterAuxiliary(context.getters.auxiliaryDataGet, auxiliaryObject);

        //При наличии записи редактируем данные
        if (recNowAuxiliary.length === 1) {
          recNowAuxiliary[0]['saved'] = 'no';
          const infoAuxiliary = recNowAuxiliary[0].info;
          infoAuxiliary.push(obj);
          recNowAuxiliary[0].info = infoAuxiliary;
        }

        //При отсутствии записи добавляем ее с изменениями
        else {
          const auxiliaryData = context.getters.auxiliaryDataGet;
          auxiliaryObject.info = [obj];
          auxiliaryData.push(auxiliaryObject)
          context.commit('setAuxiliaryData', auxiliaryData)
        }
      }
      else {
        //Проверяем корректность id
        if (!obj.id) { obj.id = -1 }



        //Проверяем существование объекта с id в данных
        const nowIndustrial = useArrayFiltered(context.getters.industrialDataGet, 'id', obj.id)

        //При наличии объекта в данных
        if (nowIndustrial.length === 1) {

          //Если активная модел "индустриал" изменяем данные объект
          if (context.getters.textModelBaseGet === 'modelIndustrial') {
            nowIndustrial[0]['saved'] = 'no';
            const Fields = context.getters.activeModelDataGet.info.params;
            for (let Field of Fields) {
              if (Field.modification) {
                nowIndustrial[0][Field.name] = obj[Field.name] ? obj[Field.name] : nowIndustrial[0][Field.name]
              }
            }
          }

          //Если активная модел "Fact" Добавляем или редактируем fact
          if (context.getters.textModelBaseGet === 'modelFact') {

            const factObject = JSON.parse(JSON.stringify(context.getters.factObjectGet));
            factObject.industrial = obj.id;
            const recNowFact = useFilterFact(context.getters.factDataGet, factObject);

            //Редактируем данные по году
            if (recNowFact.length === 1) {
              recNowFact[0]['saved'] = 'no';
              if (context.getters.activeModelDataGet.info.records === 'one') {
                recNowFact[0].info = [obj]
              }
              else {
                const infoFact = recNowFact[0].info;
                infoFact.push(obj);
                recNowFact[0].info = infoFact;
              }
            }

            //Добавляем данные по году
            else {
              const factData = context.getters.factDataGet;
              factObject.info = [obj];
              factData.push(factObject);
            }
          }
        }

        //При отсутствии объекта
        else {
          //Добавляем объект индустриал
          if (context.getters.textModelBaseGet === 'modelIndustrial') {
            const industrialObject = JSON.parse(JSON.stringify(context.getters.industrialObjectGet));
            industrialObject.id = obj.id;
            const Fields = context.getters.activeModelDataGet.info.params;
            for (let Field of Fields) {
              if (Field.modification && obj[Field.name]) {
                industrialObject[Field.name] = obj[Field.name]
              }
            }
            const allIndustrials = context.getters.industrialDataGet;
            allIndustrials.push(industrialObject);
            context.commit('setIndustrialData', allIndustrials);
          }
        }
      }
    },

    allToTable(context) {
      for (let obj of context.getters.excelImportGet) {
         context.dispatch('addToTable', obj, 1)
      }
       context.commit('setExcelImport', []);
       context.commit('setImportingData', false);
       context.commit('setActiveKeyTable', new Date());
    }

  }
}