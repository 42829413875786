<template>
  <div class="container mt-4">
    Раздел "Данные балансовые" находится в стадии тестирования
  </div>
</template>
<script>

</script>
<style scoped>
</style>
