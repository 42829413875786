<template>
  <div v-if="showFrameEcology">
    <ecology-frame></ecology-frame>
  </div>
  <div v-if="showFrameStatistic">
    <statistic-frame></statistic-frame>
  </div>
  <div v-if="showFrameTax">
    <tax-frame></tax-frame>
  </div>
  <div v-if="showFrameMedical">
    <medical-frame></medical-frame>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import EcologyFrame from '@/reports/EcologyFrame.vue';
import StatisticFrame from '@/reports/StatisticFrame.vue';
import TaxFrame from '@/reports/TaxFrame.vue';
import MedicalFrame from '@/reports/MedicalFrame.vue';


export default {
  components: {
    EcologyFrame,
    MedicalFrame,
    StatisticFrame,
    TaxFrame,
  },
  setup () {
    const store = useStore();

    const showFrameEcology = computed(() => {return store.getters.nowSlugGet === "ecology"});
    const showFrameMedical = computed(() => {return store.getters.nowSlugGet === "medical"});
    const showFrameStatistic = computed(() => {return store.getters.nowSlugGet === "statistic"});
    const showFrameTax = computed(() => {return store.getters.nowSlugGet === "tax"});

    return {
      showFrameEcology,
      showFrameMedical,
      showFrameStatistic,
      showFrameTax,
    }
  }
}
</script>
<style scoped>
.myColor {
  color: #0a689e;
}
</style>