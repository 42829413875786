export default {
  state: {

    //Линк "Базовый"
    //linkBase: 'http://127.0.0.1:8000/',
    linkBase: 'https://setcalckz.ru/',

    //Линк "Стартовый"
    linkStart: 'api/v1/basic/templates/',

    //Текст в спиннере по умолчанию
    spinnerTextDefault: {
      'ru':'Идет загрузка стартовых данных...',
      'kz':'Бастау деректері жүктелуде...',
      'en':'Start data is loading...'
    },

    //Ошибка получения стартовых данных
    errorRequestStart: {
      'code':1001,
      'detail':1,
      'ru':'Ошибка получения стартовых данных',
      'kz':'Бастау деректерін алу қатесі',
      'en':'Error receiving start data'
    },


    //Текст главной страницы по умолчанию
    txtMainPageDefault:{
       'ru':'Главная страница',
       'kz':'Басты бет',
       'en':'Home page'
    },

    textLabelDefault: {
       txtErrorsMessages:{
          'ru':'Ошибки. Сообщения',
          'kz':'Қателер. Хабарламалар',
          'en':'Errors. Messages'
       },
       btnClearName: {
         'ru':'Очистить',
         'kz':'Таза',
         'en':'Clear'
       }
    }

  },

  getters: {

    //Получение базового линка
    linkBaseGet(state) {
      return state.linkBase;
    },

    //Получение стартового линка
    linkStartGet(state) {
      return state.linkStart;
    },

    //Получение текста при стартовоой загрзке данных
    spinnerTextDefaultGet(state) {
      return state.spinnerTextDefault;
    },

    //Получение текстов дефолтной ошибки
    errorStartGet(state) {
      return state.errorStart;
    },

    errorMenuGet(state) {
      return state.errorMenu;
    },

    //Получение текста главной страницы
    txtMainPageDefaultGet(state) {
      return state.txtMainPageDefault;
    },

    //Получение подписей к данным по умолчанию
    textLabelDefaultGet(state) {
      return state.textLabelDefault;
    },

  },

  mutations: {
  },

  actions: {
  }
}