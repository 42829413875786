import useArrayFiltered from '@/workers/ArrayFiltered.js';
import useAxiosRequest from '@/workers/AxiosRequest.js';
import useErrorCreate from '@/workers/ErrorCreate.js';
import useTakeValue from '@/workers/TakeValue.js';
import usePropertyAdd from '@/workers/PropertyAdd.js';
import usePropertyDelete from '@/workers/PropertyDelete.js';


export default {
  state: {

    //Данные объектов
    industrialData: [],


    //Подгрузка объектов
    industrialFilled: false,

    //Сохранение объетов
    industrialSaved: true,

  },

  getters: {

    //Получение объектов
    industrialDataGet(state) {
      return state.industrialData
    },

    //Получение несохраненных объектов
    industrialDataNotSavedGet(state) {
      return state.industrialData.filter(function(industrial) {
        return industrial.saved === 'no';
        });
    },

    //Получение статуса загрузки объектов
    industrialFilledGet(state) {
      return state.industrialFilled
    },

    //Получение статуса сохранения объектов
    industrialSavedGet(state) {
      return state.industrialSaved
    },

    industrialObjectGet(state, getters) {
      return {
        'name':'-',
        'group': 1,
        'compound':1,
        'status':1,
        'bin':'000000000000',
        'solo':1,
        'sorting':1,
        'create':String(new Date()),
        'update':String(new Date()),
        'strainer':'0',
        'curator':getters.infoUserGet.curator,
        'user':getters.infoUserGet.id,
        'saved':'no',
        'choice':true
      }
    },

  },

  mutations: {

    //Изменяем объекты
    setIndustrialData(state, industrials) {
      state.industrialData = industrials;
    },


    //Изменяем статус подгрузки объектов
    setIndustrialFilled(state, bool) {
      state.industrialFilled = bool;
    },

    //Изменяем статус сохранения объектов
    setIndustrialSaved(state, bool) {
      state.industrialSaved = bool;
    }

  },

  actions: {

    async reqIndustrial(context, Method) {
       let data = ''
       if (Method !== 'GET') {
         data = usePropertyDelete(useArrayFiltered(context.getters.industrialDataGet, 'saved', 'no'),
                                  ['saved','create','update', 'choice']);
       }

       //Определяем токен
       const token = context.getters.nowTokenGet;

       //Получаем линк linkBase
       const linkBase = context.getters.linkBaseGet;

       //Определяем линк
       const link = context.getters.linkIndustrialGet;

       //Находим id Группы
       const groupID = useTakeValue(context.getters.groupsUserGet.params,
                                   'name', context.getters.activeGroupGet, 'id', 0);

       //Формируем параметры запроса
       const params = { group: groupID };

       //Подгружаем объекты
       const industrialObject = await useAxiosRequest(linkBase + link, Method, data, params, token);

       if (industrialObject.status) {
         //Добавляем свойство к объектам
         usePropertyAdd(industrialObject.data.posts, "saved", "yes")

         //Добавляем свойство к объектам
         usePropertyAdd(industrialObject.data.posts, "choice", true)

         //Коммитим данные объектов
         context.commit('setIndustrialData', industrialObject.data.posts);

         //Коммитим заверешие подгрузки объектов
         context.commit('setIndustrialFilled', true);
       }
       else {
         //Коммитим пустые данные
         context.commit('setIndustrialData', []);

         //Получаем и комитим ошибку
         const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                       context.getters.errorsAllGet['errorRequestIndustrial']);
         context.commit('setNowErrorText', errors);
       }
    },

    changeGroup(context) {
      const changeData = context.getters.industrialDataNotSavedGet;
      if (changeData.length) {
        if (context.getters.activeSavingGet === 'auto') {
          context.dispatch('reqIndustrial', 'PATCH');
        }

        if (context.getters.activeSavingGet === 'hand') {
          context.dispatch('reqIndustrial', 'GET');
        }

        if (context.getters.activeSavingGet === 'preventive') {
          //console.log('preventive', context.getters.industrialDataGet)
          context.commit('setSavedData', true);
        }
      }
      else {
        context.dispatch('reqIndustrial', 'GET');
      }
    }
  }
}