//Возвращает параметры селекта

export default function useParamsInterface(interfaces, name, numb, forbidden) {
  const select = {};
  const params = [];
  let option = {};
  let defaultName = '';
  if (interfaces) {
    const keys = Object.keys(interfaces);
    let calc = 0
    for (let key of keys) {
      option = {}
      if (interfaces[key]['info']['show'] === 'yes') {
        option['id'] = interfaces[key]['info']['sort'];
        option['name'] = key;
        option['value'] = interfaces[key]['info']['description'];
        option['sort'] = interfaces[key]['info']['sort'];
        params.push(option);
        calc = calc + 1
      }
      if (calc === 1) {
        defaultName = key
      }
      if (calc === numb) {
        defaultName = key
      }
    }
    select['name'] = name;
    select['default'] = defaultName;
    select['forbidden'] = forbidden;
    select['params'] = params
  }
  return select
}