<template>
  <div v-if="startFilled">
    <login-view>
    </login-view>
  </div>
  <div v-if="infoSite != 'bad'">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="card">
            <div class="card-header">
             <span v-html="infoSite.title[language]"></span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="text in infoSite.params[language]" :key="text.id">
                <span v-html="text.title"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LoginView from '@/views/LoginView.vue';


export default {
  components: {
    LoginView,
  },
  setup() {
    const store = useStore();

    //Очищаем значение слага
    store.commit('setNowSlug', '');

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем информацию о разделах приложения
    const infoSite = computed(() => store.getters.infoSiteGet);

    //Определяем подгрузку стартовых данных
    const startFilled = computed(() => store.getters.startFilledGet);

    return {
      language,
      infoSite,
      startFilled,
    }
  }
}
</script>
<style scoped>
.card-header {
  font-size: 1.1rem;
  color: #0a689e;
}

.list-group-item {
  color: #0a689e;
}
</style>