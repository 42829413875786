<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="table-responsive">
        <table
         class="table table-hover"
         :key="keyTable"
        >
          <thead>
            <tr>
              <th>
                №
              </th>
              <th scope="col" v-for="(field, index) in headerTable" :key="index">
                {{ field.title[language] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in recordsTable" :key="index" @dblclick="editData(record.id)">
              <td>
               {{ index+1 }}
              </td>
              <td v-for="(field, index) in headerTable" :key="index">
                {{ record[field.name] }}
              </td>
            </tr>
            <!--
            <tr>
              <th>
              </th>
              <th scope="col" v-for="field in headerTable" :key="field.id">
                рез.
              </th>
            </tr>
            -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    headerTable: {
      type: Object,
      required: true,
    },
    recordsTable: {
      type: Object,
    },
    language: {
      type: String,
    },
    keyTable: {
      type: String,
      Default: "0"
    },
  },
  setup(_, context) {

    function editData(id) {
      context.emit("clickTable", id)
    }

    return {
      editData
    }
 }
}
</script>

<style scoped>
.table-responsive {
  padding: 0rem;
}

.table>:not(caption)>*>* {
  padding: 0.1rem 0.1rem;
  color: #0a689e;
}
</style>
