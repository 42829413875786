<template>
  <div v-if="showFrameIndustrial">
    <industrial-frame></industrial-frame>
  </div>
  <div v-if="showFrameStandard">
    <standard-frame></standard-frame>
  </div>
  <div v-if="showFrameQuarter">
    <quarter-frame></quarter-frame>
  </div>
  <div v-if="showFrameBalance">
    <balance-frame></balance-frame>
  </div>
  <div v-if="showFrameCartography">
    <cartography-frame></cartography-frame>
  </div>
  <div v-if="showFrameEnvironment">
    <environment-frame></environment-frame>
  </div>
  <div v-if="showFrameProject">
    <project-frame></project-frame>
  </div>
  <div v-if="showFrameAuxiliary">
    <auxiliary-frame></auxiliary-frame>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AuxiliaryFrame from '@/data/AuxiliaryFrame.vue';
import BalanceFrame from '@/data/BalanceFrame.vue';
import CartographyFrame from '@/data/CartographyFrame.vue';
import EnvironmentFrame from '@/data/EnvironmentFrame.vue';
import IndustrialFrame from '@/data/IndustrialFrame.vue';
import ProjectFrame from '@/data/ProjectFrame.vue';
import StandardFrame from '@/data/StandardFrame.vue';
import QuarterFrame from '@/data/QuarterFrame.vue';


export default {
  components: {
    AuxiliaryFrame,
    BalanceFrame,
    CartographyFrame,
    EnvironmentFrame,
    IndustrialFrame,
    ProjectFrame,
    StandardFrame,
    QuarterFrame,
  },
  setup () {
    const store = useStore();

    const showFrameIndustrial = computed(() => {return store.getters.nowSlugGet === "industrials"});
    const showFrameStandard = computed(() => {return store.getters.nowSlugGet === "standards"});
    const showFrameQuarter = computed(() => {return store.getters.nowSlugGet === "quarters"});
    const showFrameBalance = computed(() => {return store.getters.nowSlugGet === "balances"});
    const showFrameCartography = computed(() => {return store.getters.nowSlugGet === "cartography"});
    const showFrameEnvironment = computed(() => {return store.getters.nowSlugGet === "environments"});
    const showFrameProject = computed(() => {return store.getters.nowSlugGet === "projects"});
    const showFrameAuxiliary = computed(() => {return store.getters.nowSlugGet === "auxiliary"});

    return {
      showFrameIndustrial,
      showFrameStandard,
      showFrameQuarter,
      showFrameBalance,
      showFrameCartography,
      showFrameEnvironment,
      showFrameProject,
      showFrameAuxiliary,
    }
  }
}
</script>
<style scoped>
.myColor {
  color: #0a689e;
}
</style>