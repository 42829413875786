//Возвращает дерево объектов из массива объектов

export default function useArrayToTree (data) {
  if (data.length > 0) {
    const map = new Map(data.map(item => [item.id, item]));
      for (let item of map.values()) {
        if (!map.has(item.parent)) {
            continue;
        }
        const parent = map.get(item.parent);
        parent.children = [...parent.children || [], item];
      }
    return [...map.values()].filter(item => !item.parent)
  }
  else {
    return data
  }
}
