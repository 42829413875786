<template>
  <div v-if="textLabel != 'bad'">
    <div class="container mt-3">
      <div class="row">
        <div class="col-8">
          <div v-if="group != 'bad'">
            <custom-select
             v-model="groupActive"
             :disabledValue="group.forbidden[language]"
             :selectOptions="group.params"
             :language="language"
             @customOption="groupChange"
            >
            </custom-select>
          </div>
        </div>
        <div class="col-2">
          <div v-if="year != 'bad'">
            <custom-select
             v-model="yearActive"
             :disabledValue="year.forbidden[language]"
             :selectOptions="year.params"
             :language="language"
             @customOption="yearChange"
            >
            </custom-select>
          </div>
        </div>
        <div class="col-2">
          <div v-if="tax != 'bad'">
            <custom-select
             v-model="taxActive"
             :disabledValue="tax.forbidden[language]"
             :selectOptions="tax.params"
             :language="language"
             @customOption="taxChange"
            >
            </custom-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed  } from 'vue';
import { useStore } from 'vuex';
import CustomSelect from "@/components/CustomSelect.vue";


export default {
  components: {
    CustomSelect,
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определение режима сохранения данных
    //const activeSaved = computed(() => store.getters.activeSavingGet);

    //Определение селекта группы пользователя
    const group = computed(() => store.getters.selectGroupGet);
    const groupActive = computed(() => store.getters.activeGroupGet);

    //Определяем селект год
    const year = computed(() => store.getters.selectYearGet);
    const yearActive = computed(() => store.getters.activeYearGet);

    //Определяем селект форм налоговой отчетности
    const tax = computed(() => store.getters.selectTaxGet);
    const taxActive = computed(() => store.getters.activeTaxGet);


    function groupChange(data) {
      store.commit('setActiveGroup', data);
      store.dispatch('changeGroup');
    }

    function yearChange(data) {
      store.commit('setActiveYear', data);
      store.dispatch('changeYearTax');
    }

    function taxChange(data) {
      console.log(data)
    }


    return {
      language,
      textLabel,

      group,
      groupActive,
      groupChange,

      year,
      yearActive,
      yearChange,

      tax,
      taxActive,
      taxChange,

    }
  }
}
</script>
<style scoped>
</style>
