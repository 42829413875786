<template>
  <!-- Отображение выбора характеристик объекта  -->
  <div class="container mt-3">
    <div class="row">
      <div class="col-5">
        <div v-if="sign != 'bad'">
          <custom-select
           v-model="signActive"
           :disabledValue="sign.forbidden[language]"
           :selectOptions="sign.params"
           :language="language"
           @customOption="signChange"
          >
          </custom-select>
        </div>
      </div>

      <div class="col-2">
        <div v-if="year != 'bad'">
          <custom-select
           v-model="yearActive"
           :disabledValue="year.forbidden[language]"
           :selectOptions="year.params"
           :language="language"
           @customOption="yearChange"
          >
          </custom-select>
        </div>
      </div>
      <div class="col-2">
        <div v-if="quarter != 'bad'">
          <custom-select
           v-model="quarterActive"
           :disabledValue="quarter.forbidden[language]"
           :selectOptions="quarter.params"
           :language="language"
           @customOption="quarterChange"
          >
          </custom-select>
        </div>
      </div>
      <div class="col-2">
        <div v-if="specific != 'bad'">
          <custom-select
           v-model="specificActive"
           :disabledValue="specific.forbidden[language]"
           :selectOptions="specific.params"
           :language="language"
           @customOption="specificChange"
          >
          </custom-select>
        </div>
      </div>
      <div class="col-1">
        <div v-if="variant != 'bad'">
          <custom-select
           v-model="variantActive"
           :disabledValue="variant.forbidden[language]"
           :selectOptions="variant.params"
           :language="language"
           @customOption="variantChange"
          >
          </custom-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomSelect from "@/components/CustomSelect.vue";


export default {
  components: {
    CustomSelect,
  },
  setup() {
    const store = useStore();

    ///Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем селект характеристик
    const sign = computed(() => store.getters.selectSignGet);
    const signActive = computed(() => store.getters.textModelDataGet);

    //Определяем селект год
    const year = computed(() => store.getters.selectYearGet);
    const yearActive = computed(() => store.getters.activeYearGet);

    //Определяем селект квартал
    const quarter = computed(() => store.getters.selectQuarterGet);
    const quarterActive = computed(() => store.getters.activeQuarterGet);

    //Определяем селект тип
    const specific = computed(() => store.getters.selectSpecificGet);
    const specificActive = computed(() => store.getters.activeSpecificGet);

    //Определяем селект вариант
    const variant = computed(() => store.getters.selectVariantGet);
    const variantActive = computed(() => store.getters.activeVariantGet);


    function signChange(data) {
      store.commit('setTextModelData', data);
      store.commit('setActiveSort', 'name');
      store.commit('setActiveKeySort', new Date());
    }

    function yearChange(data) {
      store.commit('setActiveYear', data);
      store.dispatch('changeYear');
    }

    function quarterChange(data) {
      store.commit('setActiveQuarter', data);
    }

    function specificChange(data) {
      store.commit('setActiveSpecific', data);
    }

    function variantChange(data) {
      store.commit('setActiveVariant', data);
    }

    return {
      language,
      textLabel,

      sign,
      signActive,
      signChange,

      year,
      yearActive,
      yearChange,

      quarter,
      quarterActive,
      quarterChange,

      specific,
      specificActive,
      specificChange,

      variant,
      variantActive,
      variantChange,
    }
  }
}
</script>
<style scoped>
</style>
