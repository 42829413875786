//Возвращает отсортированный массив "posts" по полю "field"
//при direction="direct" - прямая сортировка иначе обратная сортировка

export default function useArraySorted(posts, field, direction) {
  if (posts.length > 0) {
    const result = [...posts].sort(function(a,b){
      if (direction === 'direct' ? a[field] < b[field] : a[field] > b[field]) return -1;
    });
  return result
  }
  else {
    return posts
  }
}
