<template>
  <div v-if="startFilled">
    <!-- Выбор объектов -->
    <object-choice
     :textLabel = "textLabel"
     :language = "language"
     :objectGroup = "groupCurrent"
     @confirmModal = "confirmObjectChoice"
     @cancelModal = "cancelObjectChoice"
    >
    </object-choice>

    <!-- Изменение данных -->
    <change-data
     :textLabel = "textLabel"
     :language = "language"
     :fields = "postCurrent"
     @confirmModal = "confirmChangeData"
     @cancelModal = "cancelChangeData"
    >
    </change-data>

    <!-- Удаление данных -->
    <delete-data
     :textLabel = "textLabel"
     :language = "language"
     @confirmModal = "confirmDeleteData"
     @cancelModal = "cancelDeleteData"
    >
    </delete-data>

    <!-- Сохранение данных -->
    <save-data
     :textLabel = "textLabel"
     :language = "language"
     @confirmModal = "confirmSaveData"
     @cancelModal = "cancelSaveData"
    >
    </save-data>
  </div>
  <div v-if="dlgStatus">
  </div>
  <div v-if="dlgShow">
  </div>
</template>
<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';
import ChangeData from "@/dialogs/ChangeData.vue";
import DeleteData from "@/dialogs/DeleteData.vue";
import ObjectChoice from "@/dialogs/ObjectChoice.vue";
import SaveData from "@/dialogs/SaveData.vue";
//import useFieldsForm from "@/workers/FieldsForm.js";


export default {
  components: {
    ChangeData,
    DeleteData,
    ObjectChoice,
    SaveData
  },

  setup() {
    const store = useStore();

    //Определяем состояние подгрузки стартовых данных
    const startFilled = computed(() => store.getters.startFilledGet);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Получаем статус диалогового окна
    const dlgStatus = computed(() => store.getters.dlgStatusGet);

    //Отображаем диалоговое окно
    const dlgShow = computed(() => dialogShow(dlgStatus.value));

    //Определяем объекты
    const groupCurrent = computed(() => store.getters.groupCurrentGet);

    //Определяем пост с данными
    const postCurrent = computed(() => store.getters.postCurrentGet);

    let modalObjectChoice
    let modalChangeData
    let modalDeleteData
    let modalSaveData

    function dialogShow(dlgStatus) {
      if (store.getters.filteredObjectGet) {
        modalObjectChoice.show();
        store.dispatch('beforeFilterObject', false)
      }

      if (store.getters.addedDataGet) {
        modalChangeData.show();
        store.dispatch('beforeAddData', false)
      }

      if (store.getters.deletedDataGet) {
        modalDeleteData.show();
        store.dispatch('beforeDeleteData', false)
      }

      if (store.getters.savedDataGet) {
        modalSaveData.show();
        store.dispatch('beforeSaveData', false)
      }
      return dlgStatus
    }

    function confirmObjectChoice(value) {
      store.dispatch('afterFilterObject', value);
      modalObjectChoice.hide();
    }

    function cancelObjectChoice() {
      modalObjectChoice.hide();
    }

    function confirmChangeData(value) {
      //console.log('value', value)
      //store.dispatch('addToTable', value, 0);
      modalChangeData.hide();
    }

    function cancelChangeData() {
      modalChangeData.hide();
    }

    function confirmDeleteData() {
      store.dispatch('afterDeleteData')
      modalDeleteData.hide();
    }

    function cancelDeleteData() {
      modalDeleteData.hide();
    }

    function confirmSaveData() {
      store.dispatch('afterSaveData', true)
      modalSaveData.hide();
    }

    function cancelSaveData() {
      store.dispatch('afterSaveData', false)
      modalSaveData.hide();
    }

    onMounted(() => {
      modalObjectChoice = new Modal(document.getElementById('modalObjectChoice'));
      modalChangeData = new Modal(document.getElementById('modalChangeData'));
      modalDeleteData = new Modal(document.getElementById('modalDeleteData'));
      modalSaveData = new Modal(document.getElementById('modalSaveData'));
    })

    onUnmounted(() => {
      modalObjectChoice.dispose();
      modalChangeData.dispose();
      modalDeleteData.dispose();
      modalSaveData.dispose();
    })

    return {
      startFilled,
      textLabel,
      language,

      dlgShow,
      dlgStatus,

      groupCurrent,
      postCurrent,

      confirmObjectChoice,
      cancelObjectChoice,

      confirmChangeData,
      cancelChangeData,

      confirmDeleteData,
      cancelDeleteData,

      confirmSaveData,
      cancelSaveData,
    }
  }
}
</script>
<style scoped>
</style>