<template>
  <button
    type="button"
    class="btn btn-primary btn-sm me-1"
    onclick="document.getElementById('importFile').click()"
  >{{ textLabel.btnImportName[language] }}
  </button>
  <input
    type='file'
    id="importFile"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    style="display:none"
    @change="onChange"
  />
  <xlsx-read v-if="readFile"
    :file="fileExcel"
    @parsed="parserBook"
  >
  </xlsx-read>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from 'vuex';
import { XlsxRead }  from "vue3-xlsx";


export default {
  components: {
    XlsxRead
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем имя файла
    const fileExcel = ref('');

    //Определяем возможность парсинга файла
    const readFile = ref(false);


    function onChange(event){
      fileExcel.value = event.target.files ? event.target.files[0] : null;
      if (fileExcel.value) {
        readFile.value = true
      } else {
         console.log('файл пуст')
      }
    }

    function parserBook(workBook) {
      readFile.value = false

      store.commit('setImportingData', true);
      store.commit('setWorkBook', workBook);
      store.dispatch('useParserBook');
      readFile.value = true
    }

    return {
      language,
      textLabel,

      fileExcel,
      readFile,
      onChange,
      parserBook,
    }
  }
}
</script>

<style scoped>
.btn-primary {
    background-color: #0a689e;
    border-color: #0a689e;
}
</style>
