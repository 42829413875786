//Возвращает объект из LocalStorage содержащий ключи "keys" и соответствующие им значения

export default function useLocalRead(keys) {
  let obj = {};
  let value = '';
  for (let key of keys) {
    value = localStorage.getItem(key);
    obj[key] = value;
  }
  return obj
}
