import useArrayFiltered from '@/workers/ArrayFiltered.js';
import useAxiosRequest from '@/workers/AxiosRequest.js';
import usePropertyDelete from '@/workers/PropertyDelete.js';


export default {
  state: {

    //Служебные данные
    auxiliaryData: [],

    //Статус подгрузки служебных данных
    auxiliaryFilled: false,

    //Статус сохранения служебных данных
    auxiliarySaved: true,

  },

  getters: {

    //Получение служебных данных
    auxiliaryDataGet(state) {
      return state.auxiliaryData
    },

    //Получение статуса подгрузки служебных данных
    auxiliaryFilledGet(state) {
      return state.auxiliaryFilled
    },

    //Получение статуса сохранения служебных данных
    auxiliarySavedGet(state) {
      return state.auxiliarySaved
    },

    //Получение объекта модели служебных данных
    auxiliaryObjectGet(state, getters) {
      return {
        'name':'-',
        'year': getters.activeYearGet,
        'variant':getters.activeVariantGet,
        'section':getters.activePageGet,
        'code':getters.textModelDataGet,
        'info':[],
        'sorting':1,
        'update':'',
        'user':getters.infoUserGet.id,
      }
    },

  },

  mutations: {

    //Изменение служебных данных
    setAuxiliaryData(state, data) {
      state.auxiliaryData = data;
    },

    //Изменение статуса подгрузки служебных данных
    setAuxiliaryFilled(state, bool) {
      state.auxiliaryFilled = bool;
    },

    //Изменение статуса сохранения служебных данных
    setAuxiliarySaved(state, bool) {
      state.auxiliarySaved = bool;
    },

  },

  actions: {

    async reqAuxiliary(context, Method) {
       let data = ''
       if (Method !== 'GET') {
         //Получаем несохраненные данные и удаляем из них свойства
         const data = usePropertyDelete(useArrayFiltered(context.getters.factDataGet, 'saved', 'no'),
                                        ['saved']);
         for (let record of data) {
           record.info = JSON.stringify(record.info)
         }
       }

       //Определяем токен
       const token = context.getters.nowTokenGet;

       //Получаем линк linkBase
       const linkBase = context.getters.linkBaseGet;

       //Определяем линк
       const link = context.getters.linkAuxiliaryGet;

       //Формируем параметры запроса
       const params = { year: context.getters.activeYearGet };

       //Подгружаем служебные данные
       const auxiliaryData = await useAxiosRequest(linkBase + link, Method, data, params, token);

       if (auxiliaryData.status) {

         //Проходим по всему списку полученных с сервера данных
         for (let record of auxiliaryData.data.posts) {

           //Добавляем свойство к объектам
           record['saved'] = 'yes';

           //Текстовое поле 'info' преобразуем в объект
           record.info = (record.info.length > 0) ? JSON.parse(record.info) : []

         }

         ///Коммитим данные
         context.commit('setAuxiliaryData', auxiliaryData.data.posts);

         //Коммитим заверешие подгрузки данных
         context.commit('setAuxiliaryFilled', true);
       }
       else {
         //Коммитим пустые данные
         context.commit('setAuxiliaryData', []);

         //Коммитим ошибку и ее текст

       }
     }

  }
}