//Удаляет свойство у объекта в массиве объектов

export default function usePropertyDelete(posts, propertyName) {
  const newPosts = JSON.parse(JSON.stringify(posts))
  for (let post of newPosts) {
    for (let field of propertyName) {
      delete post[field]
    }
  }
  return newPosts
}