<template>
  <div v-if="showFrameLogin">
    <login-frame>
    </login-frame>
  </div>
  <div v-if="showFrameLogout">
    <logout-frame>
    </logout-frame>
  </div>
  <div v-if="showFrameRegister">
    <register-frame>
    </register-frame>
  </div>
  <div v-if="showFrameTool">
    <tool-frame>
    </tool-frame>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LoginFrame from '@/cabinet/LoginFrame.vue';
import LogoutFrame from '@/cabinet/LogoutFrame.vue';
import RegisterFrame from '@/cabinet/RegisterFrame.vue';
import ToolFrame from '@/cabinet/ToolFrame.vue';


export default {
  components: {
    LoginFrame,
    LogoutFrame,
    RegisterFrame,
    ToolFrame,

  },
  setup() {
    const store = useStore()

    const showFrameLogin = computed(() => {return store.getters.nowSlugGet === "login"})
    const showFrameLogout = computed(() => {return store.getters.nowSlugGet === "logout"})
    const showFrameRegister = computed(() => {return store.getters.nowSlugGet === "register"})
    const showFrameTool = computed(() => {return store.getters.nowSlugGet === "tools"})

    return {
      showFrameLogin,
      showFrameLogout,
      showFrameRegister,
      showFrameTool,
    }
  }
}
</script>

<style scoped>
.myColor {
  color: #0a689e;
}
</style>
