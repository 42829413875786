//Возвращает распакованный массив "posts"
import useArrayFiltered from "@/workers/ArrayFiltered.js";
import useAttributeIDAdd from "@/workers/AttributeIDAdd.js";



export default function ArrayUnpack(industrials, posts, txtModel) {
  const industrialsChoice = useArrayFiltered(industrials, 'choice', true);
  const postsChoice = posts.filter(function(item){
     let result = useArrayFiltered(industrialsChoice, 'id', item.industrial);
    return result.length
  })


  if (txtModel === 'modelIndustrial') {
    return industrialsChoice
  }

  if (txtModel === 'modelAuxiliary') {
    let obj = {}
    const postsAuxiliary = []
    if (posts.length) {
      let step = 0
      for (let post of posts) {
        for (let record of post.info) {
          obj = {}
          step += 1
          const keys = Object.keys(record)
          keys.forEach((key) => obj[key]=record[key])
          obj['id'] = step
          postsAuxiliary.push(obj)
        }
      }
    return postsAuxiliary
    }
    return posts
  }

  if (txtModel === 'modelFact' || txtModel === 'modelTax') {
    const postsNew = []
    let obj = {}
    if (posts.length) {
      for (let post of postsChoice) {
        if (typeof(post['industrial']) !== 'undefined') {
          for (let record of post.info) {
            obj = {'industrial': post['industrial']}
            const keys = Object.keys(record)
            keys.forEach((key) => obj[key]=record[key])
            obj['id'] = useAttributeIDAdd(postsNew, obj['industrial'])
            postsNew.push(obj)
          }
        }
      }
    return postsNew
    }
    else {
      return posts
    }
  }
  return []
}
