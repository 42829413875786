import { createApp } from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";


const app = createApp(App);

app
    .use(router)
    .use(store)
    .mount('#app');

