<template>
  <div v-if="userAuthorized">
    <router-view>
    </router-view>
  </div>
  <div v-if="logFilled">
    <custom-spinner
     :textSpinner="textLabel.attemptLogin[language]"
    >
    </custom-spinner>
  </div>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <form @submit.prevent="logUser">
          <div class="mb-3">
            <label for="Login" class="form-label">{{ textLabel.labUserName[language] }}</label>
            <custom-input
              type="text"
              id="Login"
              v-model="form.username.value"
              @blur="form.username.blur"
            >
            </custom-input>
              <small v-if="form.username.touched && form.username.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.username.touched && form.username.errors.minLength" class="invalid">
                {{ textLabel.txtMinNumber[language] }} - {{ textLabel.minUserName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.username.value.length }}.
              </small>
              <small v-else-if="form.username.touched && form.username.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxUserName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.username.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="Password" class="form-label">{{ textLabel.labPasswordName[language] }}</label>
            <custom-input
              type="password"
              id="Password"
              v-model="form.password.value"
              @blur="form.password.blur"
            >
            </custom-input>
              <small v-if="form.password.touched && form.password.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.password.touched && form.password.errors.minLength" class="invalid">
                {{ textLabel.txtMinNumber[language] }} - {{ textLabel.minPasswordName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.password.value.length }}.
              </small>
              <small v-else-if="form.password.touched && form.password.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxPasswordName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.password.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <custom-button type="submit" :disabled="!form.valid">
              {{ textLabel.btnLoginName[language] }}
            </custom-button>
        </div>
      </form>
      <div class="mt-3">
          <a
           class="link"
           role="button"
           data-bs-toggle="modal"
           href="#modalChangePassword"
          >{{ textLabel.txtForgotPassword[language] }}
          </a>
        </div>
      </div>
     </div>
  </div>
  <change-password
   :textLabel="textLabel"
   :language="language"
  >
  </change-password>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import ChangePassword from "@/dialogs/ChangePassword.vue";
import CustomInput from '@/components/CustomInput.vue';
import CustomButton from '@/components/CustomButton.vue';
import CustomSpinner from '@/components/CustomSpinner.vue';
import RouterView from '@/views/RouterView.vue';
import { useFormControl } from '@/controls/FormControl.js';


const required = val => !!val;
const minLength = num => val => val.length >= num;
const maxLength = num => val => val.length <= num;


export default {
  components: {
    ChangePassword,
    CustomInput,
    CustomButton,
    CustomSpinner,
    RouterView
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем авторизован или нет
    const userAuthorized = computed(() => store.getters.userAuthorizedGet);

    //Определяем отображение аутентификации
    const logFilled = ref(false);

    const form = useFormControl({
      username: {
        value: '',
        validators: {
         required,
         minLength: minLength(textLabel.value.minUserName[language.value]),
         maxLength: maxLength(textLabel.value.maxUserName[language.value])}
      },
      password: {
        value: '',
        validators: {
         required,
         minLength: minLength(textLabel.value.minPasswordName[language.value]),
         maxLength: maxLength(textLabel.value.maxPasswordName[language.value])}
      }
    })

    function logUser() {
      //Отображаем аутентификацию
      logFilled.value = true;

      //Инициализируем пользователя
      const nowUser = {
        'username': form.username.value,
        'password': form.password.value
      };

      //Коммитим пользователя
      store.commit('setNowUser', nowUser);

      //Сохраняем пользователя
      store.dispatch('reqUserLogin');
    }

    function changePassword() {
      //Разрешаем сбросить пароль
      console.log('Сбросить пароль')
    }

    return {
      language,
      textLabel,
      logFilled,
      userAuthorized,

      form,
      logUser,
      changePassword
    }
  }
}
</script>

<style scoped>
.btn-primary {
    background-color: #0a689e;
    border-color: #0a689e;
}
.invalid {
  color: red;
}
</style>
