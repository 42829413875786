<template>
  <!-- Отображение кнопок -->
  <div class="container">
    <div class="row">

      <!-- Кнопки слева  -->
      <div class="col-9">
        <div class="parent mb-2">

          <!-- Фильтрация данных -->
          <div v-if="btnDetail.btnFilter">
            <custom-button
             @click="filterData"
            >{{ textLabel.btnFilterName[language] }}
            </custom-button>
          </div>

          <!-- Импорт данных  -->
          <div v-if="btnDetail.btnImport">
            <button
             type="button"
             class="btn btn-primary btn-sm me-1"
             onclick="document.getElementById('importXML').click()"
            >{{ textLabel.btnImportName[language] }}
            </button>
            <input
             type='file'
             id="importXML"
             accept="application/xml"
             style="display:none"
             @change="importData"
            />
          </div>
        </div>
      </div>

      <!-- Кнопки справа  -->
      <div class="col-3">
        <div class="parent mb-2 float-end">

          <!-- Создание отчета по данным  -->
          <div v-if="btnDetail.btnReport">
            <custom-button
             @click="reportData"
            >{{ textLabel.btnReportName[language] }}
            </custom-button>
          </div>

          <!-- Сохранение данных  -->
          <div v-if="btnDetail.btnSave">
            <custom-button
             @click="saveData"
            >{{ textLabel.btnSaveName[language] }}
            </custom-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomButton from "@/components/CustomButton.vue";


export default {
  components: {
    CustomButton,
  },

  setup() {
    const store = useStore();
    //Определяем активную страницу
    const activePage = computed(() => store.getters.activePageGet);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем отображаемые кнопки
    const btnDetail = computed(() => store.getters.modelsToolsGet[activePage.value]['buttons']);


    function filterData() {
      store.dispatch('beforeFilterObject', true);
    }

    function importData(event){
      const fileXML = event.target.files ? event.target.files[0] : null;
      if (fileXML) {
        store.dispatch('takeXml', fileXML)
      } else {
         console.log('файл пуст')
      }
    }

    function saveData() {
      store.dispatch('changeYearTax');
    }

    function reportData() {
      store.dispatch('downloadXml');
    }

    return {
      textLabel,
      language,
      btnDetail,

      //Функционал действий
      filterData,
      importData,
      saveData,
      reportData,
    }
  }
}
</script>
<style scoped>
.parent {
  display: flex;
  float: left;
}
.btn-primary {
    background-color: #0a689e;
    border-color: #0a689e;
}
</style>