<template>
  <div v-if="startFilled">
    <div class="container mt-2 mb-2">
      <div class="row">
        <div class="col-6">{{ detailDeveloper.years[language] }} SETcalcKZ</div>
        <div class="col">{{ textLabel.phoneShort[language] }} {{ detailDeveloper.phone[language] }}</div>
        <div class="col">{{ textLabel.emailBasic[language] }} {{ detailDeveloper.email[language] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  setup() {
    const store = useStore();

    //Определяем подгрузку стартовых данных
    const startFilled = computed(() => store.getters.startFilledGet);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем данные разработчика
    const detailDeveloper = computed(() => store.getters.detailDeveloperGet);


    return {
      language,
      textLabel,
      detailDeveloper,
      startFilled
    }
  }
}
</script>

<style scoped>
</style>
