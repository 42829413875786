import { computed, reactive } from 'vue';
import { useFieldControl } from '@/controls/FieldControl.js';


export function useFormControl(init = {}) {
  const form = reactive({})
  const validKey = 'valid'

  for (const [key, value] of Object.entries(init)) {
    form[key] = useFieldControl(value)
  }

  const withoutValid = k => k !== validKey

  form[validKey] = computed(() => Object.keys(form).filter(withoutValid).every(k => form[k].valid))

  return form
}
