export default {
  state: {

    //Язык
    activeLanguage: 'ru',

    //Группа
    activeGroup: 'group1',

    //Всего групп
    activeGroups: '20',

    //Год
    activeYear: '2023',

    //Квартал
    activeQuarter: '1',

    //Специфика данных
    activeSpecific: 'current',

    //Вариант данных
    activeVariant: '1',

    //Характеристика сортировки
    activeSign: 'name',

    //Сортировка динамическая
    activeSort: 'name',

    //Фильтр дополнительный
    activeStrainer: '0',

    //Направление сортировки
    activeDirect: 'direct',

    //Действие
    activeAction: 'edit',

    //Режим ввода и экспорта
    activeBring: 'base',

    //Интерфейс детальный
    activeFace: 'base',

    //Форма налоговой отчетности
    activeTax: '870',

    //Сортировка ресурсов
    activeOutside: 'name',

    //Режим сохранения данных
    activeSaving: 'auto',

    //Задержка в ошибке
    activeDefective: '3000',

    //Номер активной страницы
    activePageNumber: '1',

    //Число постов на странице
    activePostsPage: '15',

    //Общее число страниц ресурсов
    activePageCount: '1',

    //Релиз ФНО 870
    activeRelease: '19',

    //Ключ к селекту сортировки
    activeKeySort: 'sort',

    //Ключ к таблице
    activeKeyTable: 'table',

    //Группы пользователя
    groupsUser: {},

    //Интерфейсы пользователя
    interfacesUser: {},

    //Отображается окно выбора объектов или нет
    showingObjectChoice: false,

    //Отображается окно добавления данных или нет
    showingAdditionData: false,

    //Закомичнный объект
    activeEntry: {},

  },

  getters: {

    activeLanguageGet(state) {
      return state.activeLanguage
    },

    activeGroupGet(state) {
      return state.activeGroup
    },

    activeGroupsGet(state) {
      return state.activeGroups
    },

    activeYearGet(state) {
      return state.activeYear
    },

    activeQuarterGet(state) {
      return state.activeQuarter
    },

    activeSpecificGet(state) {
      return state.activeSpecific
    },

    activeVariantGet(state) {
      return state.activeVariant
    },

    activeSignGet(state) {
      return state.activeSign
    },

    activeSortGet(state) {
      return state.activeSort
    },

    activeStrainerGet(state) {
      return state.activeStrainer
    },

    activeDirectGet(state) {
      return state.activeDirect
    },

    activeActionGet(state) {
      return state.activeAction
    },

    activeBringGet(state) {
      return state.activeBring
    },

    activeFaceGet(state) {
      return state.activeFace
    },

    activeTaxGet(state) {
      return state.activeTax
    },

    activeOutsideGet(state) {
      return state.activeOutside
    },

    activeSavingGet(state) {
      return state.activeSaving
    },

    activeDefectiveGet(state) {
      return state.activeDefective
    },

    activeWarnedGet(state) {
      return state.activeWarned
    },

    activeCounterGet(state) {
      return state.activeCounter
    },

    activeReleaseGet(state) {
      return state.activeRelease
    },

    activePageNumberGet(state) {
      return state.activePageNumber
    },

    activePostsPageGet(state) {
      return state.activePostsPage
    },

    activePageCountGet(state) {
      return state.activePageCount
    },

    activeKeySortGet(state) {
      return state.activeKeySort
    },

    activeKeyTableGet(state) {
      return state.activeKeyTable
    },

    groupsUserGet(state) {
      return state.groupsUser
    },

    interfacesUserGet(state) {
      return state.interfacesUser
    },

    showingObjectChoiceGet(state) {
      return state.showingObjectChoice
    },

    showingAdditionDataGet(state) {
      return state.showingAdditionData
    },

    activeDataTableGet(state, getters) {
      if (getters.textModelBaseGet === 'modelIndustrial') {
        return getters.industrialDataGet
      }

      if (getters.textModelBaseGet === 'modelFact') {
        const addFields = getters.modelsToolsGet[getters.activePageGet]['addFields'];
        const year = addFields.year ? Number(getters.activeYearGet) : -1;
        const quarter = addFields.quarter ? Number(getters.activeQuarterGet) : -1;
        const specific = addFields.specific ? getters.activeSpecificGet : '-';
        const variant = addFields.variant ? Number(getters.activeVariantGet) : 1;
        const section = addFields.section ? getters.activePageGet : '-';
        const code = addFields.code ? getters.textModelDataGet : '-';
        const factData = getters.factDataGet.filter(function(data) {
          return (
            data.year === year &&
            data.quarter === quarter &&
            data.specific === specific &&
            data.variant === variant &&
            data.section === section &&
            data.code === code)
      });
        return factData
      }

      if (getters.textModelBaseGet === 'modelTax') {
        const addFields = getters.modelsToolsGet[getters.activePageGet]['addFields'];
        const year = addFields.year ? Number(getters.activeYearGet) : -1;
        const section = addFields.section ? getters.activePageGet : '-';
        const code = addFields.code ? getters.textModelDataGet : '-';
        const taxData = getters.taxDataGet.filter(function(data) {
          return (
            data.year === year &&
            data.section === section &&
            data.code === code)
      });
        return taxData
      }

      if (getters.textModelBaseGet === 'modelAuxiliary') {
        const addFields = getters.modelsToolsGet[getters.activePageGet]['addFields'];
        const year = addFields.year ? Number(getters.activeYearGet) : -1;
        const variant = addFields.variant ? Number(getters.activeVariantGet) : 1;
        const section = addFields.section ? getters.activePageGet : '-';
        const code = addFields.code ? getters.textModelDataGet : '-';
        if (getters.auxiliaryDataGet.length) {
          const auxiliaryData = getters.auxiliaryDataGet.filter(function(data) {
          return (
            data.year === year &&
            data.variant === variant &&
            data.section === section &&
            data.code === code)
          });
          return auxiliaryData
        }
      }
      return []
    },

    activeEntryGet(state) {
      return state.activeEntry
    }

  },

  mutations: {

    //Изменяем язык
    setActiveLanguage(state, text) {
      state.activeLanguage = text;
    },

    //Изменяем активную группу
    setActiveGroup(state, group) {
      state.activeGroup = group;
    },

    //Изменяем число групп
    setActiveGroups(state, groups) {
      state.activeGroups = groups;
    },

    //Изменяем активный год
    setActiveYear(state, year) {
      state.activeYear = year;
    },

    //Изменяем активный квартал
    setActiveQuarter(state, quarter) {
      state.activeQuarter = quarter;
    },

    //Изменяем активный тип данных
    setActiveSpecific(state, specific) {
      state.activeSpecific = specific;
    },

    //Изменяем активный вариант данных
    setActiveVariant(state, variant) {
      state.activeVariant = variant;
    },

    //Изменяем характеристики сортировки
    setActiveSign(state, name) {
      state.activeSign = name;
    },

    //Изменяем динамическую сортировку
    setActiveSort(state, name) {
      state.activeSort = name;
    },

    //Изменяем дополнительный фильтр
    setActiveStrainer(state, name) {
      state.activeStrainer = name;
    },

    //Изменяем вектор сортировки
    setActiveDirect(state, name) {
      state.activeDirect = name;
    },

    //Изменяем действие
    setActiveAction(state, name) {
      state.activeAction = name;
    },

    //Изменяем Ввод и Экспорт данных
    setActiveBring(state, text) {
      state.activeBring = text;
    },

    //Изменяем интерфейс
    setActiveFace(state, face) {
      state.activeFace = face;
    },

    //Изменяем форму налоговой отчетности
    setActiveTax(state, text) {
      state.activeTax = text;
    },

    //Изменяем сортировку ресурсов
    setActiveOutside(state, name) {
      state.activeOutside = name;
    },

    //Изменяем режим сохранения данных
    setActiveSaving(state, text) {
      state.activeSaving = text;
    },

    //Изменяем Задержку в ошибке
    setActiveDefective(state, text) {
      state.activeDefective = text;
    },

    //Изменяем релиз ФНО 870
    setActiveRelease(state, text) {
      state.activeRelease = text;
    },

    //Изменяем состояние загрузки
    setNowLoad(state, text) {
      state.nowLoad = text;
    },

    //Изменяем текущий номер страницы
    setActivePageNumber(state, numb) {
      state.activePageNumber = String(numb);
    },

    //Изменяем число постов на страницы
    setActivePostsPage(state, numb) {
      state.activePostsPage = String(numb);
    },

    //Изменяем число страниц
    setActivePageCount(state, numb) {
      state.activePageCount = String(numb);
    },

    //Изменяем ключ обновления селекта сортировки
    setActiveKeySort(state, date) {
      state.activeKeySort = String(date);
    },

    //Изменяем ключ обновления таблицы
    setActiveKeyTable(state, date) {
      state.activeKeyTable = String(date);
    },

    //Изменяем группы пользователя
    setGroupsUser(state, groups) {
      state.groupsUser = groups;
    },

    //Изменяем интерфейсы пользователя
    setInterfacesUser(state, interfaces) {
      state.interfacesUser = interfaces;
    },

    //Изменяем отображение модального окна выбора объектов
    setShowingObjectChoice(state, bool) {
      state.showingObjectChoice = bool
    },

    //Изменяем отображение модального окна добавления данных
    setShowingAdditionData(state, bool) {
      state.showingAdditionData = bool
    },

    //Изменяем Текущий объект
    setActiveEntry(state, data) {
      state.activeEntry = data
    },

  },

  actions: {
  }
}