<template>
  <div v-if="startFilled">
    <div v-if="textError.length" class="container">
      <div class="accordion accordion-flush" id="accordionFlushMessage">
        <div class="accordion-item mt-2">
          <h2 class="accordion-header">
            <button
             class="accordion-button collapsed"
             type="button"
             data-bs-toggle="collapse"
             data-bs-target="#flush-collapseMessage"
             aria-expanded="false"
             aria-controls="flush-collapseMessage"
            >
            <b>{{ textLabel.txtErrorsMessages[language] }}</b>
            </button>
          </h2>
          <div id="flush-collapseMessage"
           class="accordion-collapse collapse"
           data-bs-parent="#accordionFlushMessage">
             <div class="accordion-body">
               <div v-for="(item, index) in textError" :key="index">
                 {{ item.dt }} - {{ item[language] }}
               </div>
               <div class="mt-2">
                 <custom-button
                  @click="clearData"
                 >
                  {{ textLabel.btnClearName[language] }}
                 </custom-button>
               </div>
             </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomButton from "@/components/CustomButton.vue";


export default {
  components: {
    CustomButton
  },
  setup() {
    const store = useStore();

    //Определяем состояние подгрузки стартовых данных
    const startFilled = computed(() => store.getters.startFilledGet);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем ошибки
    const textError = computed(() => store.getters.nowErrorTextGet);

    function clearData() {
      store.commit('setNowErrorText', [])
    }

    return {
      startFilled,
      language,
      textLabel,
      textError,
      clearData
    }
  }
}
 </script>

<style scoped>

.accordion-button {
   padding-top: 0.25rem;
   padding-right: 0.25rem;
   padding-bottom: 0.25rem;
   padding-left: 0.25rem;
   color: #0a689e;
}
.accordion-button:not(.collapsed) {
    color: #0a689e;
    background-color: #fff;
}

.accordion-body {
   padding-top: 0.25rem;
   padding-right: 0.25rem;
   padding-bottom: 0.25rem;
   padding-left: 0.25rem;
}

.MySection {
  color: #0a689e;
}
.MyInfo {
  color: #0a689e;
}
.MyError {
   color: red;
}

</style>