//Возвращает значение "res_field" из строки "number" массива "posts"
//с полем "find_field" и значением "find_value"

export default function useTakeValue(posts, find_field, find_value, res_field, number=0) {
  if (posts) {
    const record = posts.filter(post => post[find_field] === find_value)
    if (record.length > 0) {
      return record[number][res_field]
    }
    else {
      return ''
    }
  }
  else {
    return ''
  }
}
