export default {
  state: {

    //Активная страница данных
    activePage: 'Industrial',

    //Наименование основной модели
    textModelBase: 'modelIndustrial',

    //Наименование модели данных
    textModelData: 'modelObject',

  },

  getters: {

    //Получение активной страницы
    activePageGet(state) {
      return state.activePage
    },

    //Получение наименования базовой модели
    textModelBaseGet(state) {
      return state.textModelBase
    },

    //Получение наименования модели данных
    textModelDataGet(state) {
      return state.textModelData
    },

    //Получение базовой модели
    activeModelBaseGet(state, getters) {
      return getters.modelsBasicGet[getters.textModelBaseGet]
    },

    //Получение модели данных
    activeModelDataGet(state, getters) {
      return getters.modelsBasicGet[getters.textModelDataGet]
    },

  },

  mutations: {

    //Изменяем активную страцу
    setActivePage(state, page) {
      state.activePage = page;
    },

    //Изменяем наименование базовой модели
    setTextModelBase(state, text) {
      state.textModelBase = text;
    },

    //Изменяем наименование модели данных
    setTextModelData(state, text) {
      state.textModelData = text;
    },

  },

  actions: {
  }
}