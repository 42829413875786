<template>
  <!-- Отображение раздела фильтрации и сортировки объектов  -->
  <div v-if="textLabel !='bad'">
    <div class="container mt-3">
      <div class="row">

        <!-- Поисковая строка  -->
        <div class="col-2">
          <custom-input
           type="text"
           :placeholder="textLabel.txtPlaceholderFind[language]"
           v-model="searchQuery"
          >
          </custom-input>
        </div>

        <!-- Выбор поля фильтрации специфичной  -->
        <div class="col-2">
          <div v-if="strainer != 'bad'">
            <custom-select
             v-model="strainerActive"
             :disabledValue="strainer.forbidden[language]"
             :selectOptions="strainer.params"
             :language="language"
             @customOption="strainerChange"
            >
            </custom-select>
          </div>
        </div>

        <!-- Выбор отображения полей таблицы  -->
        <div class="col-2">
          <div v-if="face != 'bad'">
            <custom-select
             v-model="faceActive"
             :disabledValue="face.forbidden[language]"
             :selectOptions="face.params"
             :language="language"
             @customOption="faceChange"
            >
            </custom-select>
          </div>
        </div>

        <!-- Выбор поля сортировки таблицы  -->
        <div class="col-2">
          <div v-if="sort != 'bad'">
            <custom-select
             v-model="sortActive"
             :disabledValue="sort.forbidden[language]"
             :selectOptions="sort.params"
             :language="language"
             :keySelect="activeKeySort"
             @customOption="sortChange"
            >
            </custom-select>
          </div>
        </div>

        <!-- Направление сортировки  -->
        <div class="col-2">
          <div v-if="direct != 'bad'">
            <custom-select
             v-model="directActive"
             :disabledValue="direct.forbidden[language]"
             :selectOptions="direct.params"
             :language="language"
             @customOption="directChange"
            >
            </custom-select>
          </div>
        </div>

        <!-- Выбор действия над данными  -->
        <div class="col-2">
          <div v-if="action != 'bad'">
            <custom-select
             v-model="actionActive"
             :disabledValue="action.forbidden[language]"
             :selectOptions="action.params"
             :language="language"
             @customOption="actionChange"
            >
            </custom-select>
          </div>
        </div>
      </div>
    </div>

    <!-- Отображение таблицы  -->
    <div class="container mt-2">
      <custom-table
       :headerTable="header"
       :recordsTable="records"
       :language="language"
       :keyTable="activeKeyTable"
       @clickTable="takeID"
      >
      </custom-table>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import CustomInput from "@/components/CustomInput.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import CustomTable from "@/components/CustomTable.vue";
import useArraySearched from "@/workers/ArraySearched.js";
import useArraySorted from "@/workers/ArraySorted.js";
import useArrayUnpack from "@/workers/ArrayUnpack.js";
import useFieldsVisible from "@/workers/FieldsVisible.js";


export default {
  components: {
    CustomInput,
    CustomSelect,
    CustomTable,
  },
  setup() {
    const store = useStore();

    //Определяем активную страницу
    const activePage = computed(() => store.getters.activePageGet);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем статус пользователя
    const worker = store.getters.statusWorkerGet;

    //Определяем модель данных с учетом активной страницы
    const modelBaseText = computed(() => store.getters.textModelBaseGet);
    const modelText = computed(() => store.getters.textModelDataGet);
    const modelData = computed(() => store.getters.activeModelDataGet);

    //Определяем интерфейс к активной модели
    const modelInterface = computed(() => store.getters.modelsToolsGet[activePage.value][modelText.value]);

    //Определяем поисковую строку
    const searchQuery = ref('');

    //Определяем селект дополнительной фильтрации
    const strainer = computed(() => store.getters.selectStrainerGet);
    const strainerActive = computed(() => store.getters.activeStrainerGet);

    //Определяем селект интерфейса
    const face = computed(() => store.getters.selectInterfaceGet);
    const faceActive = computed(() => store.getters.activeFaceGet);

    //Определяем селект направления сортировки
    const direct = computed(() => store.getters.selectDirectGet);
    const directActive = computed(() => store.getters.activeDirectGet);

    //Определяем селект действия
    const action = computed(() => store.getters.selectActionGet);
    const actionActive = computed(() => store.getters.activeActionGet);

    //Получаем отображаемые поля
    const visibleHeader = computed(
    () => store.getters.interfacesBasicGet[modelInterface.value]?.['info']?.['params']?.[faceActive.value]
    );

    //Определяем данные таблицы
    const header = computed(() => useFieldsVisible(visibleHeader.value, faceActive.value, modelData.value, worker));

    const dataPosts = computed(() => useArrayUnpack(store.getters.industrialDataGet, store.getters.activeDataTableGet, modelBaseText.value));

    const sortedPosts = computed(
     () => useArraySorted(dataPosts.value, store.getters.activeSortGet, store.getters.activeDirectGet)
    );

    const searchedPosts = computed(
    () => useArraySearched(sortedPosts.value, 'strainer', takeStrainer())
    );

    const records = computed(
    () => commitRecords(useArraySearched(searchedPosts.value, store.getters.activeSortGet, searchQuery.value))
    );

    //Определяем селект сортировки объектов
    const sort = computed(() => createSelectSort(textLabel.value, header.value, modelData.value))
    const sortActive = computed(() => store.getters.activeSortGet);

    //Определяем ключи обновления данных
    const activeKeyTable = computed(() => store.getters.activeKeyTableGet);
    const activeKeySort = computed(() => store.getters.activeKeySortGet);

    function createSelectSort(textLabel, header, modelData) {
      if (modelData != 'bad') {
        store.commit('setActiveSort', 'name');
        return {"forbidden":textLabel.txtSort,"params":header}
      }
      return {"forbidden":textLabel.txtSort,"params":[]}
    }

    function takeStrainer() {
      if (store.getters.activeStrainerGet != '0') {
        return store.getters.activeStrainerGet
      }
      else {return ''}
    }

    function commitRecords(records) {
      store.commit('setExpoData', JSON.parse(JSON.stringify(records)))
      return records
    }

    function sortChange(data) {
      store.commit('setActiveSort', data);
    }

    function strainerChange(data) {
      store.commit('setActiveStrainer', data);
    }

    function directChange(data) {
      store.commit('setActiveDirect', data);
    }

    function faceChange(data) {
      store.commit('setActiveFace', data);
      store.commit('setActiveKeySort', new Date());
    }

    function actionChange(data) {
      store.commit('setActiveAction', data);
    }

    function takeID(id) {
      store.dispatch('dblClickTable', id)
    }

    return {
      //Язык и подписи к данным
      language,
      textLabel,

      //Поисковая строка
      searchQuery,

      //Фильтрация объектов
      strainer,
      strainerActive,
      strainerChange,

      //Внешний вид таблицы
      face,
      faceActive,
      faceChange,

      //Сортировка объектов
      sort,
      sortActive,
      sortChange,

      //Напрвление сортировки
      direct,
      directActive,
      directChange,

      //Действие
      action,
      actionActive,
      actionChange,

      //Заголовки и данные таблицы
      header,
      records,
      takeID,

      //Ключи обновления данных
      activeKeySort,
      activeKeyTable,
    }
  }
}
</script>
<style scoped>

</style>

