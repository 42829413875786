export default {
  state: {

    //Текст ошибки
    nowErrorText: []

  },

  getters: {

    //Получаем тексты ошибок
    nowErrorTextGet(state) {
      return state.nowErrorText
    },

  },

  mutations: {

    //Изменяем тексты ошибок
    setNowErrorText(state, text) {
      state.nowErrorText = text;
    },

  },

  actions: {
  }
}