<template>
  <div class="container mt-3">
    <div class="text-center">
      <div class="spinner-border"
           style="color: #0a689e; width: 5rem; height: 5rem;"
           role="status"
      >
      </div>
      <div style="color: #0a689e;">{{ textSpinner }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textSpinner: {
      type: String,
    },
  },
  setup() {
    return {
    }
  }
}
</script>

<style scoped>
</style>
