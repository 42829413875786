//Возвращает массив "post" из "obj"

export default function useArrayOfObject(object) {
  let obj = {}
  const post = []
  const keys = Object.keys(object)
  for (let key of keys) {
    obj = {}
    obj['name'] = key
    obj['value'] = object[key]
    post.push(obj)
  }
  return post;
}