<template>
  <div class="modal fade" id="modalChangePassword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header bg-secondary text-white">
        <div class="modal-title" id="exampleModalLabel">{{ textLabel.nameFormChangePassword[language] }}</div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="input-group flex-nowrap mt-1">
          <span class="input-group-text" id="addon-wrapping">{{ textLabel.emailBasic[language] }}</span>
          <input
            type="text"
            class="form-control"
            :placeholder="textLabel.labEmailName[language]"
            aria-label="qqq"
            aria-describedby="addon-wrapping"
          >
        </div>
        <div class="input-group flex-nowrap mt-1">
          <span class="input-group-text" id="addon-wrapping">{{ textLabel.labPasswordName[language] }}</span>
          <input
            type="password"
            class="form-control"
            :placeholder="textLabel.labNewPasswordName[language]"
            aria-label="eee"
            aria-describedby="addon-wrapping"
          >
        </div>
      </div>
      <div class="modal-footer bg-secondary text-white">
        <custom-button>{{ textLabel.btnContinueName[language] }}</custom-button>
        <custom-button data-bs-dismiss="modal">{{ textLabel.btnCancelName[language] }}</custom-button>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';


export default {
  components: {
    CustomButton,
  },
  props: {
    textLabel: {
      type: Object,
    },
    language: {
      type: String,
    },
  },
  setup() {
    return {
    }
  }
}
</script>

<style scoped>
.btn-primary {
  color: #fff;
  background-color: #0a689e;
  border-color: #0a689e;
}

.modal-header {
  padding: 0.4rem;
}

.modal-footer {
  padding: 0.1rem;
}

.form-control {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.input-group-text {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

</style>