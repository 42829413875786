<template>
  <div v-if="!startFilled">
    <custom-spinner
     :textSpinner="spinnerTextDefault[language]"
    >
    </custom-spinner>
  </div>
  <div v-if="dataTree.length">
     <nav class="navbar navbar-expand-lg navbar-light">
       <div class="container-fluid">
         <router-link
          :to="{name: 'main'}"
          class="navbar-brand"
         >
         {{ textLabel.txtGlobalTitle[language]}}
         </router-link>
           <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
           </button>
           <div
            class="collapse navbar-collapse"
            id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown" v-for="menu in dataTree" :key="menu.id">
                  <div v-if="menu.sorting < 10099">
                    <a class="nav-link dropdown-toggle"
                     v-bind:id="menu.id"
                     role="button"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                    >
                     {{ menu.name[language] }}
                    </a>
                      <ul class="dropdown-menu" v-bind:aria-labelledby="menu.id">
                        <li v-for="subData in menu.children" :key="subData.id">
                          <router-link
                           class="dropdown-item"
                           :to="{name: subData.chord, params: {slug: subData.slug }}"
                           @click="workingMenu(subData.slug)"
                          >
                          {{ subData.name[language] }}
                          </router-link>
                        </li>
                      </ul>
                  </div>
                </li>
              </ul>
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown" v-for="menu in dataTree" :key="menu.id">
                  <div v-if="menu.sorting > 10099">
                    <a
                     class="nav-link dropdown-toggle"
                     :id="menu.id"
                     role="button"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                    >
                     {{ menu.name[language] }}
                    </a>
                      <ul
                       class="dropdown-menu"
                       :aria-labelledby="menu.id">
                         <li v-for="subData in menu.children" :key="subData.id">
                           <router-link
                            class="dropdown-item"
                            :to="{name: subData.chord, params: {slug: subData.slug }}"
                            @click="workingMenu(subData.slug)"
                           >
                            {{ subData.name[language] }}
                           </router-link>
                         </li>
                       </ul>
                  </div>
                 </li>
              </ul>
              <!-- Селект выбора языка -->
              <form v-if="languageInfo">
                <language-select
                 v-model="language"
                 :selectOptions="languageInfo.params"
                 @customOption="languageChange"
                >
                </language-select>
              </form>
           </div>
       </div>
     </nav>

     <!-- Отображение сведений о странице -->
     <div>
       <section-view>
       </section-view>
     </div>

     <!-- Отображение сообщений -->
     <div>
       <message-view>
       </message-view>
     </div>

     <!-- Отображение импорта данных -->
     <div v-if="importingData">
       <custom-spinner
        :textSpinner="textLabel.importingData[language]"
       >
       </custom-spinner>
     </div>

     <!-- Отображение экспорта данных -->
     <div v-if="exportingData">
       <custom-spinner
        :textSpinner="textLabel.exportingData[language]"
       >
       </custom-spinner>
     </div>

     <!-- Отображение модальных окон -->
     <div>
       <modal-view>
       </modal-view>
     </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomSpinner from '@/components/CustomSpinner.vue';
import LanguageSelect from '@/components/LanguageSelect.vue';
import MessageView from '@/views/MessageView.vue';
import ModalView from '@/views/ModalView.vue';
import SectionView from '@/views/SectionView.vue';


export default {
  components: {
    CustomSpinner,
    LanguageSelect,
    MessageView,
    ModalView,
    SectionView
  },
  setup() {
    const store = useStore();

    //Определяем состояние подгрузки стартовых данных
    const startFilled = computed(() => store.getters.startFilledGet);

    //Подгружаем стартовые данные при их отсутствии
    store.dispatch('reqStartData');

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем дефолтный текст спиннера
    const spinnerTextDefault = store.getters.spinnerTextDefaultGet;

    //Определяем вид меню
    const dataTree = computed(
     () => store.getters.userAuthorizedGet ? store.getters.treeLoginGet : store.getters.treeLogoutGet
    );

    //Определяем селект выбора языка
    const languageInfo = computed(() => store.getters.selectsLanguageGet);

    //Определяем отрисовку модальных окон
    const showModal = computed(() => store.getters.industrialDataGet.length);

    //Определяем импорт данных
    const importingData = computed(() => store.getters.importingDataGet);

    //Определяем экспорт данных
    const exportingData = computed(() => store.getters.exportingDataGet);

    function workingMenu(slug){

      //Коммитим текущий слаг
      store.commit('setNowSlug', slug);

      //Осуществляем перезагрузку данных внешних ресурсов
      changeOutside();
    }

    function languageChange(data){
      store.commit('setActiveLanguage', data);
      changeOutside();
    }

    function changeOutside() {

      if (store.getters.nowActionGet === 'outside_source') {
        //Текущую страницу устанавливаем в 1
        store.commit('setActivePageNumber', 1);

        //Комитим возможность подгрузки ресурсов
        store.commit('setOutsideFilled', false);

        //Вызываем перезагрузку ресурсов
        store.dispatch('reqOutsideData');
      }
    }

    return {
      startFilled,
      spinnerTextDefault,
      language,
      textLabel,

      dataTree,
      workingMenu,

      languageInfo,
      languageChange,

      importingData,
      exportingData,

      showModal
    }
  }
}
</script>

<style scoped>
.navbar-light {
    background-color: #0a689e;
}
.navbar-light .navbar-brand {
    color: #fff;
    font-family: 'Arial';
}
.navbar-light .navbar-nav .nav-link {
    text-transform: uppercase;
    color: #fff;
}
.dropdown-menu, .dropdown-item {
    background-color: #0a689e;
    text-transform: uppercase;
    color: #fff;
}
.dropdown-item:hover {
    background-color: #fff;
    text-transform: uppercase;
    text-decoration: underline;
    color: #0a689e;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    text-decoration: underline;
    color: #fff;
}
</style>
