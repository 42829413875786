<template>
  <div v-for="post in posts" :key="post.id">
    <div v-if="post.card === 0">
      <card-zero
       :post="post"
       :btnText="textLabel.btnDetailName[language]"
      >
      </card-zero>
    </div>
    <div v-if="post.card === 1">
      <card-first
       :post="post"
      >
      </card-first>
    </div>
    <div v-if="post.card === 2">
      <card-second
       :post="post"
       :txtDate="textLabel.txtDate[language]"
       :btnText="textLabel.btnDetailName[language]"
      >
      </card-second>
    </div>
    <div v-if="post.card === 3">
      <card-third
       :post="post"
       :txtAcceptance="textLabel.txtAcceptance[language]"
       :txtFrom="textLabel.txtFrom[language]"
       :txtForce="textLabel.txtForce[language]"
       :txtStatus="textLabel.txtStatus[language]"
       :btnText="textLabel.btnDetailName[language]"
      >
      </card-third>
    </div>
  </div>
</template>

<script>
import CardZero from '@/cards/CardZero';
import CardFirst from '@/cards/CardFirst';
import CardSecond from '@/cards/CardSecond';
import CardThird from '@/cards/CardThird';

export default {
  components: {
    CardZero,
    CardFirst,
    CardSecond,
    CardThird
  },
  props: {
    posts: {
      type: Array,
    },
    textLabel: {
      type: Object,
    },
    language: {
      type: String,
    },
  },
  setup() {
    return {
    }
  }
}
</script>

<style scoped>
</style>
