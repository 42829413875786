<template>
  <!-- Отображение кнопок -->
  <div class="container">
    <div class="row">

      <!-- Кнопки слева  -->
      <div class="col-9">
        <div class="parent mb-2">

          <!-- Фильтрация данных -->
          <div v-if="btnDetail.btnFilter">
            <custom-button
             :disabled="!isFiltered"
             @click="filterData"
            >{{ textLabel.btnFilterName[language] }}
            </custom-button>
          </div>

          <!-- Экспорт данных -->
          <div v-if="btnDetail.btnExport">
            <export-button>
            </export-button>
          </div>

          <!-- Импорт данных  -->
          <div v-if="btnDetail.btnImport">
            <import-button>
            </import-button>
          </div>

          <!-- Создание данных  -->
          <div v-if="btnDetail.btnCreate">
            <custom-button
             @click="createData"
            >{{ textLabel.btnCreateName[language] }}
            </custom-button>
          </div>

          <!-- Добавление данных  -->
          <div v-if="btnDetail.btnAdd">
            <custom-button
             @click="addData"
            >{{ textLabel.btnAddName[language] }}
            </custom-button>
          </div>

          <!-- Обновление данных  -->
          <div v-if="btnDetail.btnUpdate">
            <custom-button
             @click="updateData"
            >{{ textLabel.btnUpdateName[language] }}
            </custom-button>
          </div>

          <!-- Удаление данных  -->
          <div v-if="btnDetail.btnDelete">
            <custom-button
             :disabled="!isDeleted"
             @click="deleteData"
            >{{ textLabel.btnDeleteName[language] }}
            </custom-button>
          </div>
        </div>
      </div>

      <!-- Кнопки справа  -->
      <div class="col-3">
        <div class="parent mb-2 float-end">

          <!-- Создание отчета по данным  -->
          <div v-if="btnDetail.btnReport">
            <custom-button
             @click="reportData"
            >{{ textLabel.btnReportName[language] }}
            </custom-button>
          </div>

          <!-- Сохранение данных  -->
          <div v-if="btnDetail.btnSave">
            <custom-button
             @click="saveData"
            >{{ textLabel.btnSaveName[language] }}
            </custom-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CustomButton from "@/components/CustomButton.vue";
import ExportButton from "@/views/ExportButton.vue";
import ImportButton from "@/views/ImportButton.vue";


export default {
  components: {
    CustomButton,
    ExportButton,
    ImportButton,
  },

  setup() {
    const store = useStore();
    //Определяем активную страницу
    const activePage = computed(() => store.getters.activePageGet);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем активность кнопки филтрации
    const isFiltered = computed(() => store.getters.industrialDataGet.length);

    //Определяем активность кнопки удаления
    const isDeleted = computed(() => store.getters.expoDataGet.length);

    //Определяем отображаемые кнопки
    const btnDetail = computed(() => store.getters.modelsToolsGet[activePage.value]['buttons']);

    function filterData() {
      store.dispatch('beforeFilterObject', true);
    }

    function createData() {
      console.log('Создать')
    }

    function addData() {
      store.dispatch('beforeAddData', true);
    }

    function updateData() {
      console.log('Обновить')
    }

    function deleteData() {
      store.dispatch('beforeDeleteData', true);
    }

    function saveData() {
      store.dispatch('saveChangeData')
    }

    function reportData() {
      console.log('Формировать')
    }

    return {
      textLabel,
      language,
      btnDetail,

      isFiltered,
      isDeleted,

      //Функционал действий
      filterData,
      createData,
      addData,
      updateData,
      deleteData,
      saveData,
      reportData,
    }
  }
}
</script>
<style scoped>
.parent {
  display: flex;
  float: left;
}
</style>