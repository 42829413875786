<template>
  <div v-if="!userAuthorized">
    <router-view>
    </router-view>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import RouterView from '@/views/RouterView.vue';


export default {
  components: {
    RouterView
  },
  setup() {
    const store = useStore();

    //Определяем авторизован или нет
    const userAuthorized = computed(() => store.getters.userAuthorizedGet);

    //Выходим из кабинета
    store.dispatch('reqUserLogout');

    return {
      userAuthorized
    }
  }
}
</script>

<style scoped>
</style>