<template>
  <div v-if="userAuthorized">
    <router-view>
    </router-view>
  </div>
  <div v-if="regFilled">
    <custom-spinner
     :textSpinner="textLabel.attemptRegister[language]"
    >
    </custom-spinner>
  </div>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <form @submit.prevent="regUser">
          <div class="mb-3">
            <label for="LastName" class="form-label">{{ textLabel.labLastName[language] }}</label>
            <custom-input
              type="text"
              id="LastName"
              v-model="form.last_name.value"
              @blur="form.last_name.blur"
            >
            </custom-input>
              <small v-if="form.last_name.touched && form.last_name.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.last_name.touched && form.last_name.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxLastName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.last_name.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="FirstName" class="form-label">{{ textLabel.labFirstName[language] }}</label>
            <custom-input
              type="text"
              id="FirstName"
              v-model="form.first_name.value"
              @blur="form.first_name.blur"
            >
            </custom-input>
              <small v-if="form.first_name.touched && form.first_name.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.first_name.touched && form.first_name.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxFirstName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.first_name.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="Business" class="form-label">{{ textLabel.labBusinessName[language] }}</label>
            <custom-input
              type="text"
              id="Business"
              v-model="form.business.value"
              @blur="form.business.blur"
            >
            </custom-input>
              <small v-if="form.business.touched && form.business.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.business.touched && form.business.errors.minLength" class="invalid">
                {{ textLabel.txtMinNumber[language] }} - {{ textLabel.maxBusinessName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.business.value.length }}.
              </small>
              <small v-else-if="form.business.touched && form.business.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxBusinessName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.business.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="Phone" class="form-label">{{ textLabel.labPhoneName[language] }}</label>
            <custom-input
              type="text"
              id="Phone"
              v-model="form.phone.value"
              @blur="form.phone.blur"
            >
            </custom-input>
              <small v-if="form.phone.touched && form.phone.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.phone.touched && form.phone.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxPhoneName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.phone.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="WatchApp" class="form-label">{{ textLabel.labWatchAppName[language] }}</label>
            <custom-input
              type="text"
              id="WatchApp"
              v-model="form.whatsapp.value"
              @blur="form.whatsapp.blur"
            >
            </custom-input>
              <small v-if="form.whatsapp.touched && form.whatsapp.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.whatsapp.touched && form.whatsapp.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxWatchAppName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.whatsapp.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="Email" class="form-label">{{ textLabel.labEmailName[language] }}</label>
            <custom-input
              type="email"
              id="Email"
              v-model="form.email.value"
              @blur="form.email.blur"
            >
            </custom-input>
              <small v-if="form.email.touched && form.email.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.email.touched && form.email.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxEmailName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.email.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="Login" class="form-label">{{ textLabel.labUserName[language] }}</label>
            <custom-input
              type="text"
              id="Login"
              v-model="form.username.value"
              @blur="form.username.blur"
            >
            </custom-input>
              <small v-if="form.username.touched && form.username.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.username.touched && form.username.errors.minLength" class="invalid">
                {{ textLabel.txtMinNumber[language] }} - {{ textLabel.minUserName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.username.value.length }}.
              </small>
              <small v-else-if="form.username.touched && form.username.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxUserName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.username.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <label for="Password" class="form-label">{{ textLabel.labPasswordName[language] }}</label>
            <custom-input
              type="password"
              id="Password"
              v-model="form.password.value"
              @blur="form.password.blur"
            >
            </custom-input>
              <small v-if="form.password.touched && form.password.errors.required" class="invalid">
                {{ textLabel.txtFieldRequired[language] }}
              </small>
              <small v-else-if="form.password.touched && form.password.errors.minLength" class="invalid">
                {{ textLabel.txtMinNumber[language] }} - {{ textLabel.minPasswordName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.password.value.length }}.
              </small>
              <small v-else-if="form.password.touched && form.password.errors.maxLength" class="invalid">
                {{ textLabel.txtMaxNumber[language] }} - {{ textLabel.maxPasswordName[language] }}. {{ textLabel.txtWordNow[language] }} {{ form.password.value.length }}.
              </small>
          </div>
          <div class="mb-3">
            <custom-button type="submit" :disabled="!form.valid">
              {{ textLabel.btnRegistrationName[language] }}
            </custom-button>
        </div>
      </form>
      </div>
     </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import CustomInput from '@/components/CustomInput.vue';
import CustomButton from '@/components/CustomButton.vue';
import CustomSpinner from '@/components/CustomSpinner.vue';
import RouterView from '@/views/RouterView.vue';
import { useFormControl } from '@/controls/FormControl.js';


const required = val => !!val;
const minLength = num => val => val.length >= num;
const maxLength = num => val => val.length <= num;


export default {
  components: {
    CustomInput,
    CustomButton,
    CustomSpinner,
    RouterView
  },
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    const userAuthorized = computed(() => store.getters.userAuthorizedGet);

    //Определяем отображение регистрации
    const regFilled = ref(false);

    const form = useFormControl({
      last_name: {
        value: '',
        validators: {required, maxLength: maxLength(textLabel.value.maxLastName[language.value])}
      },
      first_name: {
        value: '',
        validators: {required, maxLength: maxLength(textLabel.value.maxFirstName[language.value])}
      },
      business: {
        value: '',
        validators: {
         required,
         minLength: minLength(textLabel.value.minBusinessName[language.value]),
         maxLength: maxLength(textLabel.value.maxBusinessName[language.value])
        }
      },
      phone: {
        value: '',
        validators: {required, maxLength: maxLength(textLabel.value.maxPhoneName[language.value])}
      },
      whatsapp: {
        value: '',
        validators: {required, maxLength: maxLength(textLabel.value.maxWatchAppName[language.value])}
      },
      email: {
        value: '',
        validators: {required, maxLength: maxLength(textLabel.value.maxEmailName[language.value])}
      },
      username: {
        value: '',
        validators: {
         required,
         minLength: minLength(textLabel.value.minUserName[language.value]),
         maxLength: maxLength(textLabel.value.maxUserName[language.value])}
      },
      password: {
        value: '',
        validators: {
         required,
         minLength: minLength(textLabel.value.minPasswordName[language.value]),
         maxLength: maxLength(textLabel.value.maxPasswordName[language.value])}
      }
    })


    function regUser() {
      //Отображаем регистрацию
      regFilled.value = true;

      const nowUser = {
        'last_name': form.last_name.value,
        'first_name': form.first_name.value,
        'business': form.business.value,
        'phone': form.phone.value,
        'whatsapp': form.whatsapp.value,
        'email': form.email.value,
        'username': form.username.value,
        'password': form.password.value
      };

      //Коммитим пользователя
      store.commit('setNowUser', nowUser);

      //Регистрируем пользователя
      store.dispatch('reqUserRegister');
    }

    return {
      language,
      textLabel,
      regFilled,
      userAuthorized,

      form,
      regUser
    }
  }
}
</script>

<style scoped>
.btn-primary {
    background-color: #0a689e;
    border-color: #0a689e;
}
.invalid {
  color: red;
}
</style>

