<template>
  <div class="modal fade"
   id="modalObjectChoice"
   tabindex="-1"
   aria-labelledby="exampleObjectChoice"
   aria-hidden="true"
  >
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header bg-secondary text-white">
        <div
         class="modal-title"
         id="exampleObjectChoice"
        > {{ textLabel.nameFormChoiceObject[language] }}
        </div>
        <button
         type="button"
         class="btn-close"
         @click="$emit('cancelModal')"
         aria-label="Close"
        >
        </button>
      </div>
      <div class="modal-body">
        <ol>
          <li v-for="(field, index) in objectGroup" :key="index">
            <input type="checkbox" class="me-2"
             :id="field.id"
             :value="field.name"
             v-model="field.choice"
            >
            <label :for="field.id">{{ field.name }}</label>
          </li>
        </ol>
      </div>
      <div class="modal-footer bg-secondary text-white">

        <custom-button
         @click="resetData"
        >
         {{ textLabel.btnResetName[language] }}
        </custom-button>

        <custom-button
         @click="invertData"
        >{{ textLabel.btnInvertName[language] }}
        </custom-button>

        <custom-button
         @click="$emit('confirmModal', objectGroup)"
        >{{ textLabel.btnContinueName[language] }}
        </custom-button>

        <custom-button
         @click="$emit('cancelModal')"
        >{{ textLabel.btnCancelName[language] }}
        </custom-button>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';


export default {
  components: {
    CustomButton,
  },
  props: {
    textLabel: {
      type: Object,
    },
    language: {
      type: String,
    },
    objectGroup: {
      type: Array
    },
  },
  setup(props) {

    function resetData() {
      for (let attribute of props.objectGroup) {
        attribute.choice = false;
      }
    }

    function invertData() {
      for (let attribute of props.objectGroup) {
        if (attribute.choice) {
          attribute.choice = false;
        }
        else {
          attribute.choice = true;
        }
      }
    }

    return {
      resetData,
      invertData
    }
  }
}
</script>

<style scoped>
input {
    margin: 0.3rem;
}

.btn-primary {
  color: #fff;
  background-color: #0a689e;
  border-color: #0a689e;
}

.modal-header {
  padding: 0.4rem;
}

.modal-footer {
  padding: 0.1rem;
}
</style>
