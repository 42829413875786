<template>
  <div v-if="showFrameServices">
    <services-frame></services-frame>
  </div>
  <div v-if="showFrameContacts">
    <contacts-frame></contacts-frame>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ServicesFrame from '@/about/ServicesFrame.vue';
import ContactsFrame from '@/about/ContactsFrame.vue';

export default {
  components: {
    ContactsFrame,
    ServicesFrame,
  },
  setup() {
    const store = useStore()

    const showFrameServices = computed(() => {return store.getters.nowSlugGet === "services"})
    const showFrameContacts = computed(() => {return store.getters.nowSlugGet === "contacts"})

    return {
      showFrameServices,
      showFrameContacts,
    }
  }
}
</script>
<style scoped>
.myColor {
  color: #0a689e;
}
</style>

