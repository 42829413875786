import useArrayFiltered from '@/workers/ArrayFiltered.js';
import useArraySearched from '@/workers/ArraySearched.js';
import useAxiosRequest from '@/workers/AxiosRequest.js';
import useErrorCreate from '@/workers/ErrorCreate.js';
import useTakeValue from '@/workers/TakeValue.js';
import usePropertyAdd from '@/workers/PropertyAdd.js';
import usePropertyDelete from '@/workers/PropertyDelete.js';


export default {
  state: {

    //Данные модели факт
    taxData: [],

    //Подгружены данные или нет
    taxFilled: false,

    //Сохранены данные или нет
    taxSaved: true,

  },

  getters: {

     //
    taxDataGet(state) {
      return state.taxData
    },

    taxFilledGet(state) {
      return state.taxFilled
    },

    taxSavedGet(state) {
      return state.taxSaved
    },

    taxObjectGet(state, getters) {
      const addFields = getters.modelsToolsGet[getters.activePageGet]['addFields'];
      const year = addFields.year ? Number(getters.activeYearGet) : -1;
      const section = addFields.section ? getters.activePageGet : '-';
      const code = addFields.code ? getters.textModelDataGet : '-';
      return {
        'name':'-',
        'year': year,
        'section':section,
        'code':code,
        'status':1,
        'data':[],
        'info':[],
        'sorting':1,
        'update':'',
        'strainer':'0',
        'industrial':0,
        'curator':getters.infoUserGet.curator,
        'user':getters.infoUserGet.id,
        'saved':'no'
      }
    },

  },

  mutations: {

    //Изменяем данные деклараций
    setTaxData(state, data) {
      state.taxData = data;
    },

    //Изменяем статус подгрузки деклараций
    setTaxFilled(state, bool) {
      state.taxFilled = bool;
    },

    //Изменяем статус сохранения деклараций
    setTaxSaved(state, bool) {
      state.taxSaved = bool;
    },

  },

  actions: {

    takeXml(context, fn) {
      const taxDataAll = context.getters.taxDataGet;
      const taxObject = JSON.parse(JSON.stringify(context.getters.taxObjectGet));


      let reader = new FileReader();
        reader.readAsText(fn);

        reader.onload = function() {
          const domParser = new DOMParser();
          const xmlSerializer = new XMLSerializer();
          const xmlDOM = domParser.parseFromString(reader.result, "text/xml");

          const infoField = [];
          let record = [];
          let yearFno = '';
          const fno = xmlDOM.querySelector("fno");
          const forms = fno.querySelectorAll("form")
          for (let form of forms) {
            if (form.getAttribute("name") === 'form_870_00') {
              let obj = {}
              obj['code'] = fno.getAttribute("code");
              obj['version'] = fno.getAttribute("version");
              const fields = form.querySelectorAll("field")
              for (let field of fields) {
                if (field.textContent) {
                  obj[field.getAttribute("name")] = field.textContent
                  if (field.getAttribute("name") === 'iin') {
                    record = useArraySearched(context.getters.industrialDataGet, 'bin', field.textContent)
                  }
                  if (field.getAttribute("name") === 'period_year') {
                    yearFno = field.textContent
                  }
                }
              }
              if (record.length > 0 & yearFno === String(context.getters.activeYearGet)) {
                obj['name'] = record[0].name;
                infoField.push(obj);
                taxObject.industrial = record[0].id;
              }
            }
          }
          taxObject.data = xmlSerializer.serializeToString(xmlDOM);
          taxObject.info = infoField;
          if (record.length > 0 & yearFno === String(context.getters.activeYearGet)) {
            taxDataAll.push(taxObject);
          }
          else {
           //Получаем и комитим ошибку
           const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                         context.getters.errorsAllGet['errorAddTax']);
           context.commit('setNowErrorText', errors);
          }
          context.commit('setTaxData', taxDataAll);
        };

        reader.onerror = function() {
          //Получаем и комитим ошибку
          const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                        context.getters.errorsAllGet['fileNotRead']);
          context.commit('setNowErrorText', errors);
          //console.log(reader.error);
        };
        //console.log('context.getters.taxDataGet', context.getters.taxDataGet)
    },

    async reqTax(context, Method) {
       let data = ''
       if (Method !== 'GET') {
         //Получаем несохраненные данные и удаляем из них свойства
         data = usePropertyDelete(useArrayFiltered(context.getters.taxDataGet, 'saved', 'no'),
                                  ['saved', 'update']);
         for (let record of data) {
           record.info = JSON.stringify(record.info)
           record.data = JSON.stringify(record.data)
         }
       }

       //Определяем токен
       const token = context.getters.nowTokenGet;

       //Получаем линк linkBase
       const linkBase = context.getters.linkBaseGet;

       //Определяем линк
       const link = context.getters.linkTaxGet;

       //Находим id Группы
       const groupID = useTakeValue(context.getters.groupsUserGet.params,
                                   'name', context.getters.activeGroupGet, 'id', 0);

       //Формируем параметры запроса
       const params = { group: groupID, year: context.getters.activeYearGet };

       //Подгружаем текущие данные по году
       const taxData = await useAxiosRequest(linkBase + link, Method, data, params, token);

       if (taxData.status) {

         //Проходим по всему списку полученных с сервера данных
         for (let record of taxData.data.posts) {

           //Добавляем свойство к объектам
           record['saved'] = 'yes';

           //Текстовое поле 'info' преобразуем в объект
           record.info = (record.info.length > 0) ? JSON.parse(record.info) : []

           //Текстовое поле 'data' преобразуем в объект
           //record.data = (record.data.length > 0) ? JSON.parse(record.data) : []
         }

         ///Коммитим данные
         context.commit('setTaxData', taxData.data.posts);

         //Коммитим заверешие подгрузки данных
         context.commit('setTaxFilled', true);
       }
       else {
         //Коммитим пустые данные
         context.commit('setTaxData', []);

         //Получаем и комитим ошибку
         const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                       context.getters.errorsAllGet['errorRequestTax']);
         context.commit('setNowErrorText', errors);

       }
    },

    changeYearTax(context) {
      const changeTax = useArrayFiltered(context.getters.taxDataGet, 'saved', 'no');
      if (changeTax.length) {
        if (context.getters.activeSavingGet === 'auto') {
          context.dispatch('reqTax', 'PATCH');
        }

        if (context.getters.activeSavingGet === 'hand') {
          context.dispatch('reqTax', 'GET');
        }

        if (context.getters.activeSavingGet === 'preventive') {
          context.commit('setSavedData', true);
        }
      }
      else {
        context.dispatch('reqTax', 'GET');
      }
    },

    downloadXml(context) {
      for (let record of context.getters.activeDataTableGet) {
          //let domParser = new DOMParser();
          //let xmlDOM = domParser.parseFromString(record.data, "text/xml");
          //console.log('xmlDOM', xmlDOM)

          let name_object = record.info[0].name
          name_object = name_object.replace(/[^a-zа-яё0-9\s]/gi, ' ');
          name_object = name_object.replace(/\s+/g, ' ');

          let bin_object = record.info[0].iin
          let period_year = record.info[0].period_year
          let period_quarter = record.info[0].period_quarter

          let fn = period_quarter + '_' + period_year + '_' + name_object + '_' + bin_object

          let blob = new Blob([record.data], {type: 'text/xml'})
          let link = document.createElement('a')
          link.setAttribute('href', URL.createObjectURL(blob))
          link.setAttribute('download', fn)
          link.click()
      }
    }
  }
}