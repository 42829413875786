//Возвращает запись из массимва фактических данных

export default function useFilterFact(posts, post) {
  const factData = posts.filter(function(data) {
          return (
            data.year === post.year &&
            data.quarter === post.quarter &&
            data.specific === post.specific &&
            data.variant === post.variant &&
            data.section === post.section &&
            data.code === post.code &&
            data.industrial === post.industrial)
      });
  return factData
}