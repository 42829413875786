<template>
  <div>
    <!-- Отображение загруки объектов  -->
    <div v-if="!industrialFilled">
      <custom-spinner
       :textSpinner="textLabel.loadIndustrial[language]"
      >
      </custom-spinner>
    </div>

    <!-- Отображение загруки данных по году  -->
    <div v-if="!factFilled">
      <custom-spinner
       :textSpinner="textLabel.loadFact[language]"
      >
      </custom-spinner>
    </div>

    <!-- Отображение выбора группы объектов и режима ввода/экспорта  -->
    <group-view>
    </group-view>

    <!-- Отображение раздела характеристик объекта  -->
    <quarter-view>
    </quarter-view>

    <!-- Отображение раздела сортировки и фильтрации объектов  -->
    <data-view>
    </data-view>

    <!-- Отображение кнопок  -->
    <button-view>
    </button-view>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ButtonView from "@/views/ButtonView.vue";
import CustomSpinner from "@/components/CustomSpinner.vue";
import DataView from "@/views/DataView.vue";
import GroupView from "@/views/GroupView.vue";
import QuarterView from "@/views/QuarterView.vue";


export default {
  components: {
    ButtonView,
    CustomSpinner,
    DataView,
    GroupView,
    QuarterView
  },
  setup() {
    const store = useStore();

    //Инициализируем страницу
    store.commit('setActivePage', 'Quarter');

    //Инициализируем модели
    store.commit('setTextModelBase',
                 store.getters.modelsToolsGet[store.getters.activePageGet]['modelBase']);
    store.commit('setTextModelData',
                  store.getters.modelsToolsGet[store.getters.activePageGet]['modelData']);

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определяем подгрузку объектов
    const industrialFilled = computed(() => getIndustrial(store.getters.industrialFilledGet));

    //Определяем подгрузку основных данных
    const factFilled = computed(() => getYear(industrialFilled.value, store.getters.factFilledGet));

    function getIndustrial(filledIndustrial) {
      if (!filledIndustrial) {
        store.dispatch('reqIndustrial', 'GET')
      }
      return filledIndustrial
    }

    function getYear(filledIndustrial, filledFact) {
      if (filledIndustrial) {
        if (!filledFact) {
          store.dispatch('reqFact', 'GET')
        }
        return filledFact
      }
      else
        return true
    }

    return {
      language,
      textLabel,
      industrialFilled,
      factFilled
    }
  }
}
</script>
<style scoped>
</style>