import useArrayFiltered from '@/workers/ArrayFiltered.js';
import useAxiosRequest from '@/workers/AxiosRequest.js';
import useErrorCreate from '@/workers/ErrorCreate.js';
import usePropertyDelete from '@/workers/PropertyDelete.js';
import useTakeValue from '@/workers/TakeValue.js';


export default {
  state: {

    //Данные модели факт
    factData: [],

    //Подгружены данные или нет
    factFilled: false,

    //Сохранены данные или нет
    factSaved: true,

  },

  getters: {

    //
    factDataGet(state) {
      return state.factData
    },

    factDataNotSavedGet(state) {
      return state.factData.filter(function(fact) {
        return fact.saved === 'no';
        });
    },

    factFilledGet(state) {
      return state.factFilled
    },

    factSavedGet(state) {
      return state.factSaved
    },

    factObjectGet(state, getters) {
      const addFields = getters.modelsToolsGet[getters.activePageGet]['addFields'];
      const year = addFields.year ? Number(getters.activeYearGet) : -1;
      const quarter = addFields.quarter ? Number(getters.activeQuarterGet) : -1;
      const specific = addFields.specific ? getters.activeSpecificGet : '-';
      const variant = addFields.variant ? Number(getters.activeVariantGet) : 1;
      const section = addFields.section ? getters.activePageGet : '-';
      const code = addFields.code ? getters.textModelDataGet : '-';
      return {
        'name':'-',
        'year': year,
        'quarter':quarter,
        'specific':specific,
        'variant':variant,
        'section':section,
        'code':code,
        'info':[],
        'sorting':1,
        'update':'',
        'industrial':0,
        'curator':getters.infoUserGet.curator,
        'user':getters.infoUserGet.id,
        'saved':'no'
      }
    },

  },

  mutations: {

    //Изменяем основные данные
    setFactData(state, data) {
      state.factData = data;
    },

    //Изменяем статус подгрузки  данных
    setFactFilled(state, bool) {
      state.factFilled = bool;
    },

    //Изменяем статус сохранение  данных
    setFactSaved(state, bool) {
      state.factSaved = bool;
    },


  },

  actions: {

    async reqFact(context, Method) {
       let data = ''
       if (Method !== 'GET') {
         //Получаем несохраненные данные и удаляем из них свойства
         data = usePropertyDelete(useArrayFiltered(context.getters.factDataGet, 'saved', 'no'),
                                  ['saved', 'update']);

         for (let record of data) {
           record.info = JSON.stringify(record.info)
         }
       }

       //Определяем токен
       const token = context.getters.nowTokenGet;

       //Получаем линк linkBase
       const linkBase = context.getters.linkBaseGet;

       //Определяем линк
       const link = context.getters.linkFactGet;

       //Находим id Группы
       const groupID = useTakeValue(context.getters.groupsUserGet.params,
                                   'name', context.getters.activeGroupGet, 'id', 0);

       //Формируем параметры запроса
       const params = { group: groupID, year: context.getters.activeYearGet };

       //Подгружаем текущие данные по году
       const factData = await useAxiosRequest(linkBase + link, Method, data, params, token);

       if (factData.status) {

         //Проходим по всему списку полученных с сервера данных
         for (let record of factData.data.posts) {

           //Добавляем свойство к объектам
           record['saved'] = 'yes';

           //Текстовое поле 'info' преобразуем в объект
           record.info = (record.info.length > 0) ? JSON.parse(record.info) : []

         }

         ///Коммитим данные
         context.commit('setFactData', factData.data.posts);

         //Коммитим заверешие подгрузки данных
         context.commit('setFactFilled', true);
       }
       else {
         //Коммитим пустые данные
         context.commit('setFactData', []);

         //Получаем и комитим ошибку
         const errors = useErrorCreate(context.getters.nowErrorTextGet,
                                       context.getters.errorsAllGet['errorRequestFact']);
         context.commit('setNowErrorText', errors);
       }
    },

    changeYear(context) {
      const changeFact = useArrayFiltered(context.getters.factDataGet, 'saved', 'no');
      if (changeFact.length) {
        if (context.getters.activeSavingGet === 'auto') {
          context.dispatch('reqFact', 'PATCH');
        }

        if (context.getters.activeSavingGet === 'hand') {
          context.dispatch('reqFact', 'GET');
        }

        if (context.getters.activeSavingGet === 'preventive') {
          context.commit('setSavedData', true);
        }

      }
      else {
        context.dispatch('reqFact', 'GET');
      }
    }
  }
}