//Возвращает массив заголовков (name) страницы Excel:
// [{"key":"A1", "title":"ИД", 'primary':"A", "name":"id"},
//  {"key":"B1", "title":"Наименование", 'primary':"B", "name":"name"}]...

import useTakeValue from "@/workers/TakeValue.js";
import useExtractField from "@/workers/ExtractField.js";

export default function useSheetNames(modelBase, records, language) {
  //Добавляем поле extractTitle в modelBase -> model
  const model = useExtractField(modelBase, language, 'title', 'extractTitle')
  //Создаем массив с будущими заголовками страницы Excel
  const names = [];

  //Пробегаемся по titles: A, B, C, ...
  for (let record of records) {
    record["name"] = useTakeValue(model, 'extractTitle', record.title, 'name');
    if (record["name"]) {
      names.push(record)
    }
  }
  return names
}