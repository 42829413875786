<template>
  <div class="container MySection">
    {{ textSection[language] }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем раздел
    const textSection = computed(() => store.getters.nowSectionTextGet);

    return {
      language,
      textSection,
    }
  }
}
</script>

<style scoped>
.MySection {
  color: #0a689e;
 }
</style>