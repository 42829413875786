//Возвращаем уникальные (не повторяющиеся) части ключей страницы Excel:
//['!ref', 'A', 'B', 'C', 'D', 'E', 'F', '!margins']

function getClearArray(arrayData) {
  const newArray = new Set(arrayData);
  return [...newArray];
}

export default function useSheetKeys(workBook, sheetName) {
  //Создаем массив для будущих ключей
  let keys = [];

  //Получаем все ключи из объекта "workBook" страницы "sheetName"
  const keysPrimary = Object.keys(workBook.Sheets[sheetName]);

  //Создаем перменную для чистки ключей от цифр
  let keyNameReplace = '';

  for (let key of keysPrimary) {
    //Удаляем в ключе цифры и пушим в массив
    keyNameReplace = key.replace(/\d/g,'')
    keys.push(keyNameReplace)
  }
  //Удаляем дубликаты ключей из массива и возвращаем его
  return getClearArray(keys)
}
