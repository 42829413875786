//Возвращаются поля отображаемые в форме

export default function useFieldsForm(modelData, post, bring, worker='curator') {
  let fieldsForm = []
  let obj = {}

  //Получаем ключи (поля) из modelData
  const fields = Object.keys(modelData);

  //Формируем отображаемые поля
  for (let field of fields) {
    if (modelData[field]['form'][worker][bring]) {
      obj = {}
      obj['name'] = modelData[field]['name']
      obj['title'] = modelData[field]['title']
      obj['default']  = modelData[field]['default']
      if (post[modelData[field]['name']] !== 'undefined') {
        obj['value'] = post[modelData[field]['name']]
      }
      else {
        obj['value'] = ''
      }
      fieldsForm.push(obj)
    }
  }
  return fieldsForm
}
