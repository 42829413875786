<template>
<div class="container mt-5 mb-3">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <h5 class="services">{{ textLabel.projectServices[language] }}</h5>
        <ol class="list-group list-group-numbered">
            <li class="list-group-item" v-for="serviceItem in serviceSite" :key="serviceItem.id"
            >{{ serviceItem.name[language] }}
            </li>
        </ol>
      </div>
    </div>
</div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  setup() {
    const store = useStore();

    //Определяем язык
    const language = computed(() => store.getters.activeLanguageGet);

    //Определяем подписи к данным
    const textLabel = computed(() => store.getters.textLabelGet);

    //Определение услуги сервиса
    const serviceSite = computed(() => store.getters.serviceSiteGet);

    return {
      language,
      textLabel,
      serviceSite
    }
  }
}
</script>

<style scoped>
.services {
  color: #0a689e;
}
.list-group-item {
  color: #0a689e;
}
</style>
