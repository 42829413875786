<template>
  <div class="app">
    <menu-main>
    </menu-main>

    <router-view>
    </router-view>

    <footer class="footer">
      <footer-main>
      </footer-main>
    </footer>
  </div>
</template>

<script>
import FooterMain from '@/main/FooterMain.vue';
import MenuMain from '@/main/MenuMain.vue';


export default {
  components: {
    FooterMain,
    MenuMain,
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
  background-color: #0a689e;
  color: #fff;
  font-family: 'Arial';
}

</style>
