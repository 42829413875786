//Возвращаем значение идентификатора

export default function AttributeIDAdd(posts, industrial) {
  let result_id = ''
  for (let step = 1; step < 100000; step++) {
    let result = true
    result_id = '(' + String(industrial) + ')' + String(step)
    for (let post of posts) {
      if (post.id === result_id) {
        result = false
      }
    }
    if (result) return result_id
  }
  return '(' + String(industrial) + ')' + 'error'
}