import useSheetCreate from '@/workers/SheetCreate.js';
import useSheetKeys from '@/workers/SheetKeys.js';
import useSheetLimitation from '@/workers/SheetLimitation.js';
import useSheetNames from '@/workers/SheetNames.js';
import useSheetTitles from '@/workers/SheetTitles.js';
import useSheetValues from '@/workers/SheetValues.js';


export default {
  state: {

    //Экспортируемые данные
    excelExport: '',

    //Импортируемые данные
    excelImport: '',

    //Книга экселя
    workBook: {},

    //Массив данных на экспорт
    expoData: [],

    //Необходимость обработки excel данных
    excelFilled: false,

    //Данные импортируются
    importingData: false,

    //Данные экспортируются
    exportingData: false,

  },

  getters: {

    excelExportGet(state) {
      return state.excelExport
    },

    excelImportGet(state) {
      return state.excelImport
    },

    workBookGet(state) {
      return state.workBook
    },

    expoDataGet(state) {
      return state.expoData
    },

    excelFilledGet(state) {
      return state.excelFilled
    },

    importingDataGet(state) {
      return state.importingData
    },

    exportingDataGet(state) {
      return state.exportingData
    },

  },

  mutations: {
    setExcelExport(state, data) {
      state.excelExport = data
    },

    setExcelImport(state, data) {
      state.excelImport = data
    },

    setWorkBook(state, data){
      state.workBook = data
    },

    setExpoData(state, data){
      state.expoData = data
    },

    setExcelFilled(state, bool) {
      state.excelFilled = bool
    },

    setImportingData(state, bool) {
      state.importingData = bool
    },

    setExportingData(state, bool) {
      state.exportingData = bool
    },
  },

  actions: {

    useExcelExport(context) {
      const modelText = context.getters.textModelDataGet;
      const modelData = context.getters.activeModelDataGet['info']['params'];
      const excelTools = context.getters.excelToolsGet[modelText];
      const sortedPosts = context.getters.expoDataGet;
      const detail = context.getters.activeBringGet
      const worker = context.getters.statusWorkerGet;
      const language = context.getters.activeLanguageGet;

      //Формируем данные страницы Excel
      const sheet = { name: excelTools.sheet, data: [] };

      //Получаем ключи (поля) из modelData
      const fields = Object.keys(modelData);

      let record;

      //Создаем локальный (новый) массив для экспорта
      const excelPosts = JSON.parse(JSON.stringify(sortedPosts))

      if (Object.keys(excelPosts).length) {
        for (let post of excelPosts) {
          if (typeof(post['industrial']) !== 'undefined') {
            post.id = post.industrial
          }
          record = {}
          for (let field of fields) {
            if (modelData[field]['excel'][worker][detail]) {
              if (modelData[field]['prefix'].length) {
                record[modelData[field]['title'][language]] = modelData[field]['prefix'] + post[modelData[field]['name']]
              }
              else {
                record[modelData[field]['title'][language]] = post[modelData[field]['name']]
              }
            }
          }
          sheet.data.push(record)
        }
      }
      else {
        record = {}
        for (let field of fields) {
          if (modelData[field]['excel'][worker][detail]) {
            if (modelData[field]['prefix'].length) {
              record[modelData[field]['title'][language]] = modelData[field]['prefix'] + modelData[field]['default'][language]
            }
            else {
              record[modelData[field]['title'][language]] = modelData[field]['default'][language]
            }
          }
        }
        sheet.data.push(record)
      }
      context.commit('setExcelExport', [sheet])
      context.commit('setExportingData', false);
    },

    useParserBook(context) {
      const wb = context.getters.workBookGet;
      const modelText = context.getters.textModelDataGet;
      const modelData = context.getters.activeModelDataGet['info']['params'];
      const excelTools = context.getters.excelToolsGet[modelText];
      const language = context.getters.activeLanguageGet;

      if (wb.Sheets[excelTools.sheet]) {
          //Получаем ключи в Excel странице
          const keysSheet = useSheetKeys(wb, excelTools.sheet);

          //Проверяем данные на лимиты
          const limitationSheet = useSheetLimitation(wb, excelTools, keysSheet);

          if (limitationSheet) {
            //Комитим необходимость обработки excel данных
            context.commit('setExcelFilled', true);

            //Получаем заголовки (title) в Excel странице
            const titlesSheet = useSheetTitles(wb, excelTools.sheet, keysSheet);

            //Получаем заголовки (name)
            const namesSheet = useSheetNames(modelData, titlesSheet, language);

            //Получаем "чистые" данные из Excel страницы
            const valuesSheet = useSheetValues(wb, excelTools.sheet, namesSheet, limitationSheet);

            //Получаем импортируемые объекты
            const importObjects = useSheetCreate(modelData, valuesSheet);

            //Комитим импортные данные
            context.commit('setExcelImport', importObjects);

            //console.log('importObjects', importObjects)

            //Обрабатываем данные
            context.dispatch('allToTable');
          }
        }
    }
  },
}